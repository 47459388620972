import * as config from './config';
import * as qstr from '../qtools/qstr';
import * as SystemTypes from './systemTypes';

// Used if e.g. site is at Netlify (public) then don't display certain internal pages.
// Also used so that when (local), then code-based editing of local files is enabled.
// window.location.href (local on Windows) = http://localhost:3000/manageQuotes
// window.location.href (on Netflify) = https://onespace.netlify.app/manageQuotes
export const currentApplicationLocation = () => {
	// netlify
	// pages.dev = CloudFlare
	if (window.location.href.includes('pages.dev')) {
		return SystemTypes.ApplicationLocation.public
	}
	return SystemTypes.ApplicationLocation.local
};

export const siteIsLocal = () => {
	return config.currentApplicationLocation() === SystemTypes.ApplicationLocation.local;
}

export const getApplicationPath = () => {
	let dirName = __dirname;
	dirName = dirName.replace('/qtools', '');
	return qstr.chopRight(dirName, 'system');
};

export const currentLocalEnvironment = (): SystemTypes.CurrentLocalEnvironment => {
	// console.log(`__dirname:` + __dirname);
	// wk: C:\edward\nwo\onespace\src\datapod\classes
	// hm: C:\edward\filesForWeeklyBackup\LEARN2020\onespace\src\datapod\classes
	const dirName = __dirname; // TODO: fix this as it seems to always return "/", (not at work)
	// return SystemTypes.CurrentLocalEnvironment.wk;
	if (qstr.contains(dirName, 'nwo')) {
		return SystemTypes.CurrentLocalEnvironment.wk;
	}
	return SystemTypes.CurrentLocalEnvironment.hm;
};