import SmartDate from 'datapod/classes/smartDate';

/**
 * Returns date/year like "Oct 04" unless it's another year then "Oct 04, 2013"
 * 
 * qdat.smartDateWithYear('2020-10-04 03:49:29');
 * 
 * Oct 04
 * 
 * Oct 04, 2013
 */
export const smartDateWithYear = (dateTime: string) => {
	const smartDate = new SmartDate(dateTime);
	return smartDate.smartDateWithYear();
};

/**
 * Returns date/year like "Oct 04" unless it's another year then "Oct 04, 2013"
 * 
 * qdat.smartDateWithYear('2020-10-04 03:49:29');
 * 
 * Monday, Nov 02
 * 
 * Monday, Nov 02, 2013
 */
export const smartDateWithWeekdayAndYear = (dateTime: string) => {
	const smartDate = new SmartDate(dateTime);
	return smartDate.smartDateWithWeekdayAndYear();
};

/**
 * Returns date/year like "Oct 04" unless it's another year then "Oct 04, 2013"
 * 
 * qdat.smartDateWithYear('2020-10-04 03:49:29');
 * 
 * Mon, Nov 02
 * 
 * Mon, Nov 02, 2013
 */
export const smartDateWithAbbreviatedWeekdayAndYear = (dateTime: string) => {
	const smartDate = new SmartDate(dateTime);
	return smartDate.smartDateWithAbbreviatedWeekdayAndYear();
};

export const smartDateIsToday = (dateTime: string) => {
	const smartDate = new SmartDate(dateTime);
	return smartDate.isToday();
};

export const smartDateIsYesterday = (dateTime: string) => {
	const smartDate = new SmartDate(dateTime);
	return smartDate.isYesterday();
};


