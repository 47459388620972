import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/stylesPageQuotes.scss';
// import $ from 'jquery';

const items = require('custom/data/items_quotes.json');

const pageTitle = 'Quotes';

interface MainState {
	filteredItems: any[],
	searchText: string
}

class Quotes extends Component<{}, MainState> {
	constructor(props: any) {
		super(props);

		this.state = {
			filteredItems: [],
			searchText: ''
		};
	}

	componentDidMount() {
		const descendingRank: any = (a: any, b: any) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
		const filteredItems = items.sort(descendingRank);
		this.setState({
			filteredItems
		});
		// $('#mainSearch').focus();
	}

	searchKeywords = (event: any) => {
		const searchText: string = event.target.value;
		this.setState({
			searchText,
			filteredItems: items.filter((m: any) => m.text.toUpperCase().includes(searchText.toUpperCase()))
		});
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
				<div className="searchArea">
					<div className="searchRow">
						<input id="mainSearch" type="text" value={this.state.searchText} className="form-control input-sm searchBox" onChange={this.searchKeywords} />
					</div>
				</div>
				<h2 className="pageTitle">Quotes</h2>
				{this.state.filteredItems.map(item => {
					return (
						<div className="quote" key={item.text}>
							{item.text} ({item.rank})
						</div>
					)
				})}
			</div>
		);
	}
}

export default Quotes;