/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/stylesPageWebDevCourse.scss';
import { getUnits } from 'custom/models/model_webDevCourse';
import { FaUserGraduate } from 'react-icons/fa';
import { BsLightningFill, BsFileCheck } from 'react-icons/bs';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import * as config from 'datapod/classes/config';
import * as SystemTypes from 'datapod/classes/systemTypes';

const units = getUnits();

const pageTitle = 'Web Development Course';

interface IProps {
    changeSiteTitle: any
}

function PageWebDevCourse(props: IProps) {
    const [searchText, setSearchText] = useState('');
    useEffect(() => {
        props.changeSiteTitle(pageTitle);
    }, []);
    return (
        <div className="pageWebDevCourse">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {config.currentApplicationLocation() === SystemTypes.ApplicationLocation.public && (
                <div>you do not have access to this page</div>
            )}
            {config.currentApplicationLocation() !== SystemTypes.ApplicationLocation.public && (
                <div>
                    <div className="searchBox">
                        <input type="text" className="form-control" onChange={(e) => setSearchText(e.target.value)} placeholder="search curriculum" autoFocus />
                    </div>
                    {units.map((unit, index) => {
                        return (
                            <div key={index}>
                                <h1>{unit.title}</h1>
                                <ul>
                                    {unit.subunits?.map((subunit: any, subunitIndex: number) => {
                                        return (
                                            <div>
                                                {(searchText.trim() === '' || (subunit.bulkSearchText.toUpperCase().includes(searchText.toUpperCase()))) && (
                                                    <li key={subunitIndex} className="subunit"><h2>{subunit.title}</h2>
                                                        <ul>
                                                            {subunit.sections?.map((section: any, sectionIndex: number) => {
                                                                return (
                                                                    <li key={sectionIndex} className="section">
                                                                        {(section.formattedDay && (searchText.trim() === '' || (section.bulkSearchText.toUpperCase().includes(searchText.toUpperCase())))) && (
                                                                            <div className="dayLabel">
                                                                                {section.formattedDay}
                                                                            </div>
                                                                        )}
                                                                        {(searchText.trim() === '' || (section.bulkSearchText.toUpperCase().includes(searchText.toUpperCase()))) && (
                                                                            <h3>
                                                                                <div>
                                                                                    {section.kind === 'know' && (
                                                                                        <FaUserGraduate className="icon" />
                                                                                    )}
                                                                                    {section.kind === 'do' && (
                                                                                        <BsLightningFill className="icon" />
                                                                                    )}
                                                                                    {section.kind === 'review' && (
                                                                                        <BsFileCheck className="icon" />
                                                                                    )}
                                                                                    {section.kind === 'todo' && (
                                                                                        <HiOutlinePencilAlt className="icon todoIcon" />
                                                                                    )}
                                                                                </div>
                                                                                <div>
                                                                                    {section.title}
                                                                                    <span className="overview">{section.overview}</span>
                                                                                </div>
                                                                            </h3>
                                                                        )}
                                                                        <ul className="sectionItems">
                                                                            {section.sectionItems.map((sectionItem: any, sectionItemsIndex: number) => (
                                                                                <li key={sectionItemsIndex}>{sectionItem.kindName.toUpperCase()}: <a href={sectionItem.url}>{sectionItem.title}</a></li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>

                                                    </li>
                                                )}
                                            </div>
                                        )
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            )}
        </div >
    )
}

export default PageWebDevCourse;