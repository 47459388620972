import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/stylesPageForays.scss';
import * as qsys from 'datapod/qtools/qsys';
import * as qstr from 'datapod/qtools/qstr';
import * as qdat from 'datapod/qtools/qdat';
import { IForay, getForays } from 'custom/models/model_forays';
import { GoPencil } from 'react-icons/go';
import * as config from 'datapod/classes/config';
import * as SystemTypes from 'datapod/classes/systemTypes';


const pageTitle = 'Forays';

interface TheProps {
	changeSiteTitle: any
}

interface MainState {
	allItems: IForay[],
	filteredItems: IForay[],
	searchText: string
}

class Forays extends Component<TheProps, MainState> {
	constructor(props: any) {
		super(props);

		this.state = {
			allItems: getForays(),
			filteredItems: [],
			searchText: ''
		};
	}

	componentDidMount() {
		const initialSort: any = (a: any, b: any) => a.systemWhenCreated > b.systemWhenCreated ? -1 : a.systemWhenCreated < b.systemWhenCreated ? 1 : 0;
		const filteredItems: IForay[] = this.state.allItems.sort(initialSort);

		this.setState({
			filteredItems
		});
		this.buildHowManyText(filteredItems.length);

		// act on URL parameters
		const id: number = qstr.forceStringAsInteger(qsys.getParameterValueFromUrl('id'));
		if (id !== 0) {
			this.displaySingleItem(id);
		} else {
			const searchText = qsys.getParameterValueFromUrl('searchText');
			if (!qstr.isEmpty(searchText)) {
				this.setState({
					searchText
				});
				this.displaySearchResults(searchText);
			}
		}
	}

	displaySingleItem = (id: number) => {
		const filteredItems: IForay[] = this.state.allItems.filter((m: any) => m.id === id);
		this.setState({
			filteredItems
		});
		this.buildHowManyText(1);
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	};

	displayAll = () => {
		this.setState({
			searchText: '',
			filteredItems: this.state.allItems
		});
		qsys.changeBrowserState(document, 'forays', '', '', pageTitle);
		this.props.changeSiteTitle(`${this.state.filteredItems.length} Forays`);
	};

	getCurrentItem = (): IForay => {
		const item = this.state.filteredItems[0];
		qsys.changeBrowserState(document, 'forays', 'id', String(item.id), `${item.category.toUpperCase()} FORAY: ${item.title}`);
		return item;
	};

	displaySearchResults = (searchText: string) => {
		const theFilteredItems: IForay[] = this.state.allItems.filter((m: any) => qstr.searchTextMatches(searchText, [m.title, m.body].join('|')));
		this.setState({
			searchText,
			filteredItems: theFilteredItems
		});
		if (qstr.isEmpty(searchText)) {
			qsys.changeBrowserState(document, 'forays', '', '', pageTitle);
		} else {
			qsys.changeBrowserState(document, 'forays', 'searchText', searchText, `Forays Search: "${searchText}"`);
		}
		this.buildHowManyText(theFilteredItems.length);
	}

	searchKeywords = (event: any) => {
		const searchText: string = event.target.value;
		this.displaySearchResults(searchText);
	}

	convertBodyToBodyParsed = (foray: IForay): string => {
		return qstr.parseOutline(foray.body, 'forays');
	}

	buildHowManyText(totalItemsShowing: number) {
		if (totalItemsShowing === this.state.allItems.length) {
			this.props.changeSiteTitle(`${totalItemsShowing} Forays`);
		} else {
			this.props.changeSiteTitle(`${totalItemsShowing} of <a class="siteTitleLink" href="forays">${this.state.allItems.length} Forays</a>`);
		}
	}

	buildSmartItemLink(item: IForay) {
		return `forays?id=${item.id}`;
	}


	render() {
		return (
			<div className="pageForays">
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>

				{/* SEARCH  */}
				{(this.state.filteredItems.length !== 1 || this.state.searchText !== '') && (
					<div className="searchArea">
						<div className="searchRow">
							<input id="mainSearch" placeholder="search forays" type="text" value={this.state.searchText} className="form-control input-sm searchBox" onFocus={this.searchKeywords} onChange={this.searchKeywords} />
						</div>
					</div>
				)}

				{/* ========== MULTIPLE RECORDS ========== */}
				{this.state.filteredItems.length > 1 &&
					(
						<div className="listItemForays">
							{this.state.filteredItems.map((item: IForay, index: number) => {
								return (
									<div key={index} className="overview">
										<a href={this.buildSmartItemLink(item)}>
											<div className="header"><span className="createDate">{qdat.smartDateWithYear(item.systemWhenCreated)}</span> <span className="category">{item.category}</span></div>
											<div className="title">{item.title}
											</div>
										</a>
									</div>
								)
							})}
						</div>
					)
				}

				{/* ========== ONE RECORD ========== */}
				{this.state.filteredItems.length === 1 &&
					(
						<div className="item">
							<div className="header">
								<div><span className="createDate">{qdat.smartDateWithYear(this.getCurrentItem().systemWhenCreated)}</span> <span className="category">{this.getCurrentItem().category}</span></div>
								<div className="headerRow">
									<div className="title">{this.getCurrentItem().title}
										{
											config.currentApplicationLocation() === SystemTypes.ApplicationLocation.local &&
											(
												<a target="_blank" rel="noopener noreferrer" href={`http://localhost:29900/manageForay?returnUrl=forays%C2%A7id=${this.getCurrentItem().id}&command=edit&id=${this.getCurrentItem().id}`}><GoPencil className="editIcon" /></a>
											)
										}
									</div>
								</div>
							</div>
							<div className="body">
								<div className="codeArea" dangerouslySetInnerHTML={{ __html: this.convertBodyToBodyParsed(this.getCurrentItem()) }} />
							</div>
						</div>
					)
				}

				{/* ========== NO RECORDS ========== */}
				{this.state.filteredItems.length === 0 &&
					(
						<div>
							Sorry, no items were found with this criteria.
						</div>
					)
				}

				{/* ========== NOT AUTHORIZED ========== */}
				{false &&
					(
						<div className="alert alert-warning">
							<strong>This page is no longer public.</strong>
						</div>
					)
				}
			</div>
		);
	}
}

export default Forays;