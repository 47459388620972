import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/stylesPageQuizItems.scss';
import * as qsys from 'datapod/qtools/qsys';
import * as qstr from 'datapod/qtools/qstr';
import { IQuizItem, getQuizItems } from 'custom/models/model_quizItems'

const pageTitle = 'Quiz Items';

interface TheProps {
    changeSiteTitle: any
}
interface MainState {
    allItems: IQuizItem[],
    filteredItems: IQuizItem[],
    searchText: string
}

class QuizItems extends Component<TheProps, MainState> {
    constructor(props: any) {
        super(props);

        this.state = {
            allItems: getQuizItems(),
            filteredItems: [],
            searchText: ''
        };
    }

    componentDidMount() {
        const initialSort: any = (a: any, b: any) => a.systemWhenCreated > b.systemWhenCreated ? -1 : a.systemWhenCreated < b.systemWhenCreated ? 1 : 0;
        const filteredItems: IQuizItem[] = this.state.allItems.sort(initialSort);

        this.setState({
            filteredItems
        });
        this.buildHowManyText(filteredItems.length);

        // act on URL parameters
        const id: number = qstr.forceStringAsInteger(qsys.getParameterValueFromUrl('id'));
        if (id !== 0) {
            this.displaySingleItem(id);
        } else {
            const searchText = qsys.getParameterValueFromUrl('searchText');
            if (!qstr.isEmpty(searchText)) {
                this.setState({
                    searchText
                });
                this.displaySearchResults(searchText);
            }
        }
    }

    displaySingleItem = (id: number) => {
        const filteredItems: IQuizItem[] = this.state.allItems.filter((m: any) => m.id === id);
        this.setState({
            filteredItems
        });
        this.buildHowManyText(1);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    displayAll = () => {
        this.setState({
            searchText: '',
            filteredItems: this.state.allItems
        });
        qsys.changeBrowserState(document, 'quizItems', '', '', pageTitle);
        this.props.changeSiteTitle(`${this.state.filteredItems.length} QuizItems`);
    };

    getCurrentItem = (): IQuizItem => {
        const item = this.state.filteredItems[0];
        qsys.changeBrowserState(document, 'quizItems', 'id', String(item.id), `${item.category.toUpperCase()} QUIZITEM: ${item.description}`);
        return item;
    };

    displaySearchResults = (searchText: string) => {
        const theFilteredItems: IQuizItem[] = this.state.allItems.filter((m: any) => qstr.searchTextMatches(searchText, [m.description].join('|')));
        this.setState({
            searchText,
            filteredItems: theFilteredItems
        });
        if (qstr.isEmpty(searchText)) {
            qsys.changeBrowserState(document, 'quizItems', '', '', pageTitle);
        } else {
            qsys.changeBrowserState(document, 'quizItems', 'searchText', searchText, `Quiz Items Search: "${searchText}"`);
        }
        this.buildHowManyText(theFilteredItems.length);
    }

    searchKeywords = (event: any) => {
        const searchText: string = event.target.value;
        this.displaySearchResults(searchText);
    }

    convertBodyToBodyParsed = (quizItem: IQuizItem): string => {
        return qstr.parseOutline(quizItem.body, 'quizItems');
    }

    convertSolutionToSolutionParsed = (codeExercise: IQuizItem): string => {
        return qstr.parseOutline(codeExercise.body, 'quizItems');
    }

    buildHowManyText(totalItemsShowing: number) {
        if (totalItemsShowing === this.state.allItems.length) {
            this.props.changeSiteTitle(`${totalItemsShowing} Quiz Items`);
        } else {
            this.props.changeSiteTitle(`${totalItemsShowing} of <a class="siteTitleLink" href="quizItems">${this.state.allItems.length} Quiz Items</a>`);
        }
    }

    render() {
        return (
            <div className="pageQuizItems">
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>

                {/* SEARCH  */}
                {(this.state.filteredItems.length !== 1 || this.state.searchText !== '') && (
                    <div className="searchArea">
                        {/* <div className="searchRow">
                            <input id="mainSearch" placeholder="search quizItems" type="text" value={this.state.searchText} className="form-control input-sm searchBox" onFocus={this.searchKeywords} onChange={this.searchKeywords} />
                        </div> */}
                    </div>
                )}

                {/* ========== MULTIPLE RECORDS ========== */}
                {this.state.filteredItems.length > 1 &&
                    (
                        <div className="listItemQuizItems">
                            {this.state.filteredItems.map((item: IQuizItem, index: number) => {
                                return (
                                    <div>
                                        <div key={index} className="quizItem">
                                            <div className="category">{item.category}</div>
                                            <div className="description">{item.description}</div>
                                            <div className="codeArea" dangerouslySetInnerHTML={{ __html: item.htmlBody }} />
                                            <div className="answerArea">
                                                <h2 className="title">Fill blanks with these answers:</h2>
                                                <div>{item.answers.map(answer => (<span className="answer">{answer}</span>))}
                                                </div>
                                                <div className="codeLink"><a href={item.codeUrl} rel="noopener noreferrer" target="_blank">View code at GitHub</a></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div >
                    )
                }

                {/* ========== ONE RECORD ========== */}
                {
                    this.state.filteredItems.length === 1 &&
                    (
                        <div>
                            <div className="quizItem">
                                <div className="category">{this.getCurrentItem().category}</div>
                                <div className="description">{this.getCurrentItem().description}</div>
                                <div className="codeArea" dangerouslySetInnerHTML={{ __html: this.getCurrentItem().htmlBody }} />
                                <div className="answerArea">
                                    <h2 className="title">Fill blanks with these answers:</h2>
                                    <div>{this.getCurrentItem().answers.map(answer => (<span className="answer">{answer}</span>))}
                                    </div>
                                    <div className="codeLink"><a href={this.getCurrentItem().codeUrl} rel="noopener noreferrer" target="_blank">View code at GitHub</a></div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {/* ========== NO RECORDS ========== */}
                {this.state.filteredItems.length === 0 &&
                    (
                        <div>
                            Sorry, no items were found with this criteria.
                        </div>
                    )
                }

                {/* ========== NOT AUTHORIZED ========== */}
                {false &&
                    (
                        <div className="alert alert-warning">
                            <strong>This page is no longer public.</strong>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default QuizItems;