import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.tsx';

ReactDOM.render(
    <>
        <App />
    </>,
	document.getElementById('root')
);
