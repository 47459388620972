import forays from 'custom/data/items_forays.json';

export interface IForayRaw {
	id: number;
	category: string;
	title: string;
	rank: string;
	body: string;
	extras: string;
	systemWhenCreated: string;
	systemWhoCreated: string;
}

export interface IForay {
	id: number;
	category: string;
	title: string;
	rank: number;
	body: string;
	extras: string;
	systemWhenCreated: string;
	systemWhoCreated: string;
	status: string;
}

export const buildForay = (forayRaw: IForayRaw) => {
	return {
		id: forayRaw.id,
		category: forayRaw.category,
		title: forayRaw.title,
		rank: parseFloat(forayRaw.rank),
		body: forayRaw.body,
		extras: forayRaw.extras,
		systemWhenCreated: forayRaw.systemWhenCreated,
		systemWhoCreated: forayRaw.systemWhoCreated,
		status: 'success'
	}
}

export const getForays = () : IForay[] => {
	const items: IForay[] = [];
	forays.forEach((itemRaw: IForayRaw) => {
		const item: IForay = buildForay(itemRaw);
		items.push(item);
	});
	return items;
}
