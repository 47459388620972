/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
import dataPageHbb from 'custom/data/documentType_pageHbb.json';

const buildLinkItem = (text: string, url: string, rank: number = 2.5, category = 'none') => {
    // const sortNumber = (Math.floor(Math.random() * 5) + 1 + rank);
    const sortNumber = (Math.floor(Math.random() * 500) + 1) / 100;
    // return { text: `${text} (${sortNumber})`, url, sortNumber };
    return { text, url, sortNumber, category };
}

const buildLinkItems = (language: string, person: any) => {
    const linkItems = [];
    const displayText = `${person.name}`;
    linkItems.push(buildLinkItem(`${displayText} in British news`, `http://news.google.co.uk/news?q=${person.name}`, person.rank, 'britishNews'));
    linkItems.push(buildLinkItem(`${displayText} in American news`, `https://news.google.com/search?q=${person.name}&hl=en-US&gl=US&ceid=US:en`, person.rank, 'americanNews'));
    linkItems.push(buildLinkItem(`${displayText} in Australian news`, `https://news.google.com/search?q=${person.name}&hl=en-AU&gl=AU&ceid=AU:en`, person.rank, 'australianNews'));

    linkItems.push(buildLinkItem(`${displayText} in deutschen Nachrichten`, `https://news.google.com/search?q=${person.name}&hl=de&gl=DE&ceid=DE:de`, person.rank, 'germanNews'));
    linkItems.push(buildLinkItem(`${displayText} in österreichischen Nachrichten`, `https://news.google.com/search?q=${person.name}&hl=de&gl=AT&ceid=AT:de`, person.rank, 'austrianNews'));
    linkItems.push(buildLinkItem(`${displayText} in Schweizer Nachrichten`, `https://news.google.com/search?q=${person.name}&hl=de&gl=CH&ceid=CH:de`, person.rank, 'swissNews'));

    linkItems.push(buildLinkItem(`${displayText} dans les nouvelles française`, `https://news.google.com/search?q=${person.name}&hl=fr&gl=FR&ceid=FR:fr`, person.rank, 'frenchNews'));
    linkItems.push(buildLinkItem(`${displayText} dans les nouvelles canadiennes`, `https://news.google.com/search?q=${person.name}&hl=fr-CA&gl=CA&ceid=CA:fr`, person.rank, 'canadaNews'));

    linkItems.push(buildLinkItem(`${displayText} in Videos`, `https://www.youtube.com/results?search_query=${person.name}&sp=EgIIBQ%253D%253D in English`, person.rank, 'videos'));
    // linkItems.push(buildLinkItem(`${displayText} in Videos auf Deutsch`, `https://www.youtube.com/results?search_query=${person.name}&sp=EgIIBQ%253D%253D auf Deutsch`, person.rank, 'videoGerman'));
    // linkItems.push(buildLinkItem(`${displayText} in Videos en français`, `https://www.youtube.com/results?search_query=${person.name}&sp=EgIIBQ%253D%253D en français`, person.rank, 'videoFrench'));
    return linkItems;
}

const getLinkItemsForPeople = (language: string, languageObject: any) => {
    const { people } = languageObject;
    const linkItems = [];
    if (Array.isArray(people)) {
        for (const person of people) {
            linkItems.push(...buildLinkItems(language, person));
        }
    }
    return linkItems;
}

const getLinkItemsForLanguage = (language: string, languageObject: any) => {
    const linkItems = [];
    linkItems.push(...getLinkItemsForPeople(language, languageObject));
    return linkItems;
};

const getLinkItems = () => {
    const linkItems = [];
    for (const [language, languageObject] of Object.entries(dataPageHbb.languages)) {
        linkItems.push(...getLinkItemsForLanguage(language, languageObject));
    }
    linkItems.sort((a, b) => b.sortNumber - a.sortNumber);
    return linkItems;
};

const getVideoItems = () => {
    const videoItems = [];
    for (const [language, languageObject] of Object.entries(dataPageHbb.languages)) {
        const { people } = languageObject;
        for (const person of people) {
            if (person.videos) {
                for (const video of person.videos) {
                    videoItems.push({
                        preText: `${person.name}`,
                        name: video.name,
                        url: video.url,
                        postText: `(${language})`
                    })
                }
            }
        }
    }
    return videoItems;
};

const getArticleItems = () => {
    const articleItems = [];
    for (const [language, languageObject] of Object.entries(dataPageHbb.languages)) {
        const { people } = languageObject;
        for (const person of people) {
            if (person.articles) {
                for (const article of person.articles) {
                    articleItems.push({
                        preText: `${person.name}`,
                        name: article.name,
                        url: article.url,
                        postText: `(${language})`
                    })
                }
            }
        }
    }
    return articleItems;
};

const getPodcastItems = () => {
    const podcastItems = [];
    for (const [language, languageObject] of Object.entries(dataPageHbb.languages)) {
        const { people } = languageObject;
        for (const person of people) {
            if (person.podcasts) {
                for (const podcast of person.podcasts) {
                    podcastItems.push({
                        preText: `${person.name}`,
                        name: podcast.name,
                        url: podcast.url,
                        postText: `(${language})`
                    })
                }
            }
        }
    }
    return podcastItems;
};

const getHbbData = () => {
    return {
        linkedItems: getLinkItems(),
        videoItems: getVideoItems(),
        podcastItems: getPodcastItems(),
        articleItems: getArticleItems()
    }
};

export default getHbbData;