import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import * as qstr from 'datapod/qtools/qstr';
import * as qdat from 'datapod/qtools/qdat';
import 'custom/styles/pageHome.scss';
import { IForay, getForays } from 'custom/models/model_forays';
import { IMiniFramework, getMiniFrameworks } from 'custom/models/model_miniFrameworks';

const howtoItems = require('custom/data/items_howtos.json');
const techItems = require('custom/data/items_techItems.json');
const quizItems = require('custom/data/items_quizItems.json');

interface MainState {
	forays: IForay[],
	miniFrameworks: IMiniFramework[],
	allItems: any[],
	dayGroupItems: any[]
}

const pageTitle = 'Edward Tanguay - All My Projects';
class Home extends Component<{}, MainState> {
	constructor(props: any) {
		super(props);

		this.state = {
			forays: getForays(),
			miniFrameworks: getMiniFrameworks(),
			allItems: [],
			dayGroupItems: []
		};
	}

	componentDidMount() {
		this.setState({
			dayGroupItems: this.createDisplayItems()
		});
	}

	createDisplayItems() {

		this.fillAllItemsWith(howtoItems, 'howtos');
		this.fillAllItemsWith(techItems, 'techItems');
		this.fillAllItemsWith(this.state.forays, 'forays');
		this.fillAllItemsWith(this.state.miniFrameworks, 'miniFrameworks');
		this.fillAllItemsWith(quizItems, 'quizItems');

		const initialSort: any = (a: any, b: any) => a.systemWhenCreated > b.systemWhenCreated ? -1 : a.systemWhenCreated < b.systemWhenCreated ? 1 : 0;
		const sortedAllItems: any[] = this.state.allItems.sort(initialSort);

		const dayGroupItems: any[] = [];
		let holdDate: string = '';
		let dayItems: any[] = [];
		let itemDate: string = '';
		sortedAllItems.forEach((item: any) => {
			itemDate = item.systemWhenCreated.substring(0, 10);
			if (holdDate !== itemDate) {
				if (!qstr.isEmpty(holdDate)) {
					dayGroupItems.push({
						dateTitle: qdat.smartDateWithWeekdayAndYear(holdDate),
						todayText: qdat.smartDateIsToday(holdDate) ? ' - Today' : '',
						yesterdayText: qdat.smartDateIsYesterday(holdDate) ? ' - Yesterday' : '',
						dayItems
					});
				}
				dayItems = [];
				holdDate = itemDate;
			}
			dayItems.push({
				id: item.id,
				itemTypeIdCode: item.itemTypeIdCode,
				itemTypeTitle: item.itemTypeTitle,
				title: item.title,
				category: item.category,
				publishDate: itemDate
			});
		});
		if (dayItems.length > 0) {
			dayGroupItems.push({
				dateTitle: qdat.smartDateWithWeekdayAndYear(holdDate),
				dayItems
			});
		}
		return dayGroupItems;
	}

	fillAllItemsWith(anyItems: any[], itemTypeIdCode: string) {
		anyItems.forEach((item: any) => {
			let itemTypeTitle = '';
			let category = '';
			let title = '';

			switch (itemTypeIdCode) {
				case 'howtos':
					itemTypeTitle = 'howto';
					category = item.category;
					title = item.title;
					break;
				case 'techItems':
					itemTypeTitle = 'tech item';
					category = item.title;
					title = qstr.capitalizeFirstLetter(qstr.getExtrasValue(item.extras, 'quick'));
					break;
				case 'forays':
					itemTypeTitle = 'foray';
					category = item.category;
					title = item.title;
					break;
				case 'miniFrameworks':
					itemTypeTitle = 'miniFramework';
					category = item.category;
					title = item.title;
					break;
				case 'quizItems':
					itemTypeTitle = 'quiz item';
					category = item.category;
					title = item.description;
					break;
				default:
					break;
			}

			this.state.allItems.push(
				{
					'id': item.id,
					'itemTypeIdCode': itemTypeIdCode,
					'systemWhenCreated': item.systemWhenCreated,
					'itemTypeTitle': itemTypeTitle,
					'category': category,
					'title':title
				}
			)
		})
	}

	render() {
		return (
			<div className="pageHome">
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
				<div className="siteMessage">
					<strong>NOTE:</strong> The content on this site may no longer being regularly updated. For my newest content, please visit: <a href="https://edwardtanguay.vercel.app/howtos">edwardtanguay.vercel.app/howtos</a>
				</div>

				<div className="theTitle">Recent items:</div>
				{this.state.dayGroupItems.map(dayGroupItem => {
					return (
						<div>
							<div className="dateTitle">{dayGroupItem.dateTitle} <span className="todayText">{dayGroupItem.todayText}</span><span className="yesterdayText">{dayGroupItem.yesterdayText}</span></div>
							<ul>{dayGroupItem.dayItems.map((item: any) => {
								return (
									<li key={item.id}><a href={`${item.itemTypeIdCode}?id=${item.id}`} className="itemLink"><span className="itemKind">{item.itemTypeTitle.toUpperCase()}</span> - <span className="itemCategory">{item.category}</span> - <span className="itemTitle">{item.title}</span></a></li>
								)
							})}
							</ul>
						</div>
					)
				})}
			</div>
		);
	}
}

export default Home;