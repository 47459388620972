/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/stylesPageHbb.scss';
import getHbbData from 'custom/models/model_hbb';

const pageTitle = 'High-Bar Internet Browsing';

interface IProps {
    changeSiteTitle: any
}

function PageHbb(props: IProps) {
    const [showIntro, setShowIntro] = useState(false);
    const [hbbData] = useState(getHbbData());
    useEffect(() => {
        props.changeSiteTitle(pageTitle);
    }, []);
    return (
        <div className="page_hbb">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {!showIntro && (
                <p>This page solves the problem of...[<span className="moreLink" onClick={() => setShowIntro(!showIntro)}>read more</span>]</p>
            )}
            {showIntro && (
                <>
                    <p>This page solves the problem of browsing the Internet only to find yourself immersed in an echo chamber of exaggerated news, targeted advertising, emotional pap, and shallow pop-culture garbage.</p>
                    <p>Using this page will raise your ethical and intellectual bar by offering a very wide assortment of randomly sorted links to news, blogs, podcasts, and videos generated from a growing database of influential people in the field of technology as well as critical voices in ethics, politics, relgion and current events in global society, both past and present.</p>
                    <p>In order to avoid an echo chamber, I include people from the full range of political views from left to right (from Chris Hedges to Alex Jones), and from different countries and in numerous languages.</p>
                    <p>I hope the links on this page lead you to content that is both fascinating and offensive to you, so that you encounter people with whom you agree and disagree on important issues in order to engage these people in argumentative conversation. Now get on it!</p>
                    <p>This page is a project of <a href="ifc">The Intellectual Fight Club</a>.</p>
                    <hr />
                </>
            )}

            {hbbData.linkedItems.map((linkedItem: any, index: number) => {
                return (
                    <>
                        <span className={`linkItem ${linkedItem.category}`} key={index}><a href={linkedItem.url} target="_blank">{linkedItem.text}</a></span>
                        {index < hbbData.linkedItems.length - 1 && (
                            <span className="separationDot" />
                        )}
                    </>
                )
            })}
            <hr />
            <h4>Selected Videos:</h4>
            <ul>
                {hbbData.videoItems.map((videoItem: any) => {
                    return (
                        <li>{videoItem.preText} - <a href={videoItem.url}>{videoItem.name}</a> {videoItem.postText}</li>
                    )
                })}
            </ul>
            <h4>Selected Podcasts:</h4>
            <ul>
                {hbbData.podcastItems.map((podcastItem: any) => {
                    return (
                        <li>{podcastItem.preText} - <a href={podcastItem.url}>{podcastItem.name}</a> {podcastItem.postText}</li>
                    )
                })}
            </ul>
            <h4>Selected Articles:</h4>
            <ul>
                {hbbData.articleItems.map((articleItem: any) => {
                    return (
                        <li>{articleItem.preText} - <a href={articleItem.url}>{articleItem.name}</a> {articleItem.postText}</li>
                    )
                })}
            </ul>
        </div>
    )
}

export default PageHbb;