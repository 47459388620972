import * as qdat from 'datapod/qtools/qdat';
import fitnessDays from 'custom/data/items_fitnessDays.json';

export interface IFitnessDayRaw {
	id: number;
	date: string;
	workout: string;
	weight: string;
	eat: string;
	notes: string;
	extras: string;
	systemWhenCreated: string;
	systemWhoCreated: string;
}

export interface IFitnessDay {
	id: number;
	date: string;
	workout: string;
	weight: string;
	eat: string;
	notes: string;
	extras: string;
	systemWhenCreated: string;
	systemWhoCreated: string;
	weekDayAndYear: string;
}

export const buildFitnessDay = (fitnessDayRaw: IFitnessDayRaw) => {
	return {
		id: fitnessDayRaw.id,
		date: fitnessDayRaw.date,
		workout: fitnessDayRaw.workout,
		weight:fitnessDayRaw.weight, 
		eat:fitnessDayRaw.eat,
		notes:fitnessDayRaw.notes, 
		extras: fitnessDayRaw.extras,
		systemWhenCreated: fitnessDayRaw.systemWhenCreated,
		systemWhoCreated: fitnessDayRaw.systemWhoCreated,
		weekDayAndYear: qdat.smartDateWithAbbreviatedWeekdayAndYear(fitnessDayRaw.date) 
	}
}

export const getFitnessDays = (): IFitnessDay[] => {
	const items: IFitnessDay[] = [];
	fitnessDays.forEach((itemRaw: IFitnessDayRaw) => {
		const item: IFitnessDay = buildFitnessDay(itemRaw);
		items.push(item);
	});
	return items;
}