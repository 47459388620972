import miniFrameworks from 'custom/data/items_miniFrameworks.json';

export interface IMiniFrameworkRaw { 
	id: number;
	idCode: string;
	url: string;
	title: string;
	description: string;
	rank: string;
	extras: string;
	systemWhenCreated: string;
	systemWhoCreated: string;
    pathAndFileName: string;
}

export interface IMiniFramework {
	id: number;
	idCode: string;
	url: string;
	title: string;
	description: string;
	rank: number;
	extras: string;
	systemWhenCreated: string;
    systemWhoCreated: string;
    pathAndFileName: string;
}

export const buildMiniFramework = (miniFrameworkRaw: IMiniFrameworkRaw) => {
	return {
		id: miniFrameworkRaw.id,
		idCode: miniFrameworkRaw.idCode,
		url: miniFrameworkRaw.url,
		title: miniFrameworkRaw.title,
		description: miniFrameworkRaw.description,
		rank: parseFloat(miniFrameworkRaw.rank),
		extras: miniFrameworkRaw.extras,
		systemWhenCreated: miniFrameworkRaw.systemWhenCreated,
        systemWhoCreated: miniFrameworkRaw.systemWhoCreated,
        pathAndFileName: miniFrameworkRaw.pathAndFileName
	}
}

export const getMiniFrameworks = () : IMiniFramework[] => {
	const items: IMiniFramework[] = [];
	miniFrameworks.forEach((itemRaw: IMiniFrameworkRaw) => {
		const item: IMiniFramework = buildMiniFramework(itemRaw);
		items.push(item);
	});
	return items;
}