import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'datapod/styles/pageShowcaseFlexPictureCards.scss';

const pageTitle = 'Showcase: Flex Picture Cards';

interface TheProps {
	changeSiteTitle: any
}
interface MainState {
}

class PageShowcaseFlexPictureCards extends Component<TheProps, MainState> {
	constructor(props: any) {
		super(props);

		this.state = {
		};
	}

	componentDidMount() {
		this.props.changeSiteTitle(pageTitle);
	}

	render() {
		return (
			<div className="pageShowcaseFlexPictureCards">
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>

				<div className="description">
					<p>This is a responsive site that on computer-width shows three picture cards in a row, and on smartphone-width shows one. The margins work well.</p>
					<p>Area of improvement: the interim space between computer-width and smartphone-width is quite jump and makes little sense, so it would be good to smooth that out.</p>
				</div>

				<section className="cards">

					<article className="card">
						<div className="innerWrapper">
							<figure className="thumbnail">
								<img src="customImages/showcase_flexPictureCaptions/testImage.png" alt="Une bananne qui ressemble à un oiseau" />
							</figure>
							<div className="card-content">
								<h2>Test Article</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas a mauris quam. Lorem
								ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget urna nec massa vehicula
				vulputate.</p>
							</div>
						</div>
					</article>

					<article className="card">
						<div className="innerWrapper">
							<figure className="thumbnail">
								<img src="customImages/showcase_flexPictureCaptions/testImage.png" alt="Une bananne qui ressemble à un oiseau" />
							</figure>
							<div className="card-content">
								<h2>Test Article</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas a mauris quam. Lorem
								ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget urna nec massa vehicula
				vulputate.</p>
							</div>
						</div>
					</article>

					<article className="card">
						<div className="innerWrapper">
							<figure className="thumbnail">
								<img src="customImages/showcase_flexPictureCaptions/testImage.png" alt="Une bananne qui ressemble à un oiseau" />
							</figure>
							<div className="card-content">
								<h2>Test Article</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas a mauris quam. Lorem
								ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget urna nec massa vehicula
vulputate.</p>
							</div>
						</div>
					</article>

					<article className="card">
						<div className="innerWrapper">
							<figure className="thumbnail">
								<img src="customImages/showcase_flexPictureCaptions/testImage.png" alt="Une bananne qui ressemble à un oiseau" />
							</figure>
							<div className="card-content">
								<h2>Test Article</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas a mauris quam. Lorem
								ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget urna nec massa vehicula
vulputate.</p>
							</div>
						</div>
					</article>

					<article className="card">
						<div className="innerWrapper">
							<figure className="thumbnail">
								<img src="customImages/showcase_flexPictureCaptions/testImage.png" alt="Une bananne qui ressemble à un oiseau" />
							</figure>
							<div className="card-content">
								<h2>Test Article</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas a mauris quam. Lorem
								ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget urna nec massa vehicula
vulputate.</p>
							</div>
						</div>
					</article>

					<article className="card">
						<div className="innerWrapper">
							<figure className="thumbnail">
								<img src="customImages/showcase_flexPictureCaptions/testImage.png" alt="Une bananne qui ressemble à un oiseau" />
							</figure>
							<div className="card-content">
								<h2>Test Article</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas a mauris quam. Lorem
								ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget urna nec massa vehicula
vulputate.</p>
							</div>
						</div>
					</article>

					<article className="card">
						<div className="innerWrapper">
							<figure className="thumbnail">
								<img src="customImages/showcase_flexPictureCaptions/testImage.png" alt="Une bananne qui ressemble à un oiseau" />
							</figure>
							<div className="card-content">
								<h2>Test Article</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas a mauris quam. Lorem
								ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget urna nec massa vehicula
vulputate.</p>
							</div>
						</div>
					</article>

					<article className="card">
						<div className="innerWrapper">
							<figure className="thumbnail">
								<img src="customImages/showcase_flexPictureCaptions/testImage.png" alt="Une bananne qui ressemble à un oiseau" />
							</figure>
							<div className="card-content">
								<h2>Test Article</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas a mauris quam. Lorem
								ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget urna nec massa vehicula
				vulputate.</p>
							</div>
						</div>
					</article>


				</section>


			</div>
		);
	}
}

export default PageShowcaseFlexPictureCards;