import rawCodeExercises from 'custom/data/items_codeExercises.json';
import * as qstr from 'datapod/qtools/qstr';

export interface IRawCodeExercise {
    id: number;
    category: string;
    title: string;
    challenge: string;
    solution: string;
    extras: string;
    systemWhenCreated: string;
    systemWhoCreated: string;
}

export interface ICodeExercise {
    id: number;
    category: string;
    title: string;
    challenge: string;
    solution: string;
    extras: string;
    systemWhenCreated: string;
    systemWhoCreated: string;
    idCode: string;
    points: number;
    rank: number;
    hint: string;
    webdev: string;
    imagePathAndFileName: string;
}

export const buildCodeExercise = (codeExerciseRaw: IRawCodeExercise) => {
    const idCode = qstr.getExtrasValue(codeExerciseRaw.extras, 'idCode');
    return {
        id: codeExerciseRaw.id,
        category: codeExerciseRaw.category,
        title: codeExerciseRaw.title,
        challenge: codeExerciseRaw.challenge,
        solution: codeExerciseRaw.solution,
        extras: codeExerciseRaw.extras,
        systemWhenCreated: codeExerciseRaw.systemWhenCreated,
        systemWhoCreated: codeExerciseRaw.systemWhoCreated,
        idCode,
        points: qstr.getExtrasValue(codeExerciseRaw.extras, 'points'),
        rank: qstr.getExtrasValue(codeExerciseRaw.extras, 'rank'),
        hint: qstr.getExtrasValue(codeExerciseRaw.extras, 'hint'),
        webdev: qstr.getExtrasValue(codeExerciseRaw.extras, 'webdev'),
        imagePathAndFileName: `customImages/codeExercises/${idCode}.png`
    }
}

export const getCodeExercises = (): ICodeExercise[] => {
    const items: ICodeExercise[] = [];
    rawCodeExercises.forEach((itemRaw: IRawCodeExercise) => {
        const item: ICodeExercise = buildCodeExercise(itemRaw);
        items.push(item);
    });
    return items;
}