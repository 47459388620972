import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/pageIfc.scss';

const pageTitle = 'Intellectual Fight Club';

interface TheProps {
    changeSiteTitle: any
}
interface MainState {
}

class PageIfc extends Component<TheProps, MainState> {
    constructor(props: any) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="pageIfc">
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                <h1>The Intellectual Fight Club</h1>
                <div className="intro">
                    <div>
                        <img src="customImages/about/edward_tanguay_profile.jpg" alt="Edward Tanguay" />
                    </div>
                    <div>
                        <p>In 2020 I abandoned work on a book I had been writing since 2016 on the topic of raising the intellectual and ethical bar of the United States culture. I abandoned the book mainly due to the overwhelming inability and unwillingness of Americans to take this topic seriously, their inability to be critical of themselves and our country, their aggressiveness both on the right and left to shut people up who talk about it, and thus the lack of a market for a critical book of this nature.</p>
                        <p>If you are interested in this topic, there are other books which have good arguments on this cultural rot and its origins. Here are three I can recommend:
                        </p>
                        <p>
                            <a href="https://www.amazon.com/The-American-Unreason-Susan-Jacoby/dp/1400096383">The Age of American Unreason</a>
                        </p>
                        <p>
                            <a href="https://www.amazon.com/Amusing-Ourselves-Death-Discourse-Business/dp/014303653X">Amusing Ourselves to Death: Public Discourse in the Age of Show Business</a>
                        </p>
                        <p>
                            <a href="https://www.amazon.com/-/de/dp/0812978900">Fantasyland: How America Went Haywire: A 500-Year History </a>
                        </p>
                        <p>So instead of publishing a book on raising the ethical and intellectual bar of America, I condenced my research and experiences down to the following code of ethics designed to raise your own ethical and intellectual bar in order to keep yourself and your family safe in the American culture, a veritable fantasyland of fake-friendly people, religious wackiness, shallow bullshit, underlying greed, ethical posing, and psychological gaslighting.</p>

                        <p>Stay safe! And most of all, enjoy the fight! :-)</p>
                    </div>
                </div >
                <h1>Rules of the Intellectual Fight Club</h1>
                <ul>
                    <li>You are already a member of the Intellectual Fight Club, no need to join.</li>
                    <li>You are open, honest, outspoken, curious, rigorous, courageous, argumentative, accurate, and critical of everybody and everything, including the club.</li>
                    <li>You expose, counter and combat bullshit, corruption, lying, fakery, shallowness, cowardice, manipulation and gaslighting in all of us, in all your friends, your partner, your family members, your coworkers, your boss, your teachers, your students, your politicians, and the news media.</li>
                    <li>You educate yourself constantly.</li>
                    <li>You are well-trained in argumentative conversation and debate, have a deep knowledge of both informal fallacies as well as manipulative conversational tactics and know how to effectively counter these.</li>
                    <li>You never ignore, block, or defriend anyone.</li>
                    <li>If someone ignores, blocks, or defriends you, you seek them out and re-engage them in conversation.</li>
                    <li>You live as if Freedom of Speech is tattooed on your fucking biceps.</li>
                    <li>When others go low, you go lower than they go: you hold them accountable for their bullshit in public, let them feel your rising ethical and intellectual bar hit them in the chin on its way up.</li>
                    <li>When others go high, you go higher than they go: challenge them, make them think, make them smarter.</li>
                    <li>If you engage in humor, you make it high, subtle, sophisticated wit.</li>
                    <li>You enjoy talking about any topic at any time with any person at any publicity level. No topic is ever forbidden.</li>
                    <li>When people avoid topics, you bring the topics back up again.</li>
                    <li>When people don't answer questions, you ask those questions of them again.</li>
                    <li>You seek out and expose contradictions in everyone's thinking, including your own, and you hold yourself and others accountable for resolving those contradictions.</li>
                    <li>You are honest with everyone, including yourself, regardless of the consequences. You treat everyone fairly, not just some people. Political correctness  and identity politics mean nothing to you.</li>
                    <li>You encourage free speech in everyone, and you hold them accountable for that speech in conversation.</li>
                    <li>You are never physically violent, but your honesty hurts, forces people to think, and changes people's opinions and behavior.</li>
                    <li>You are not loyal to any person, you are loyal only to the principles of the Intellectual Fight Club.</li>
                </ul>
                <div className="disclaimer">
                    <h1>Disclaimer:</h1>
                    <div className="body">
                        <p>Applying these rules to your life may very well jeopardize your relationships with friends, family, and coworkers, and could even lead to losing your job, etc. Many people don't want you to be critical of them, of their religion, or of their country. But if you are interested in honest relationships and keeping people accountable for what they do and say, it's necessary.</p>
                        <p>In my experience, people who leave your life because you were honestly critical of them, are quickly replaced by more interesting people who are more open and honest with you. Any commotion that being more honest and critical causes in your life will eventually lead to deeper, more sincere relationships and greater happiness in the long run.</p>
                        <p>I guarantee that if you apply these rules to your life, you will see immediate results of reduced bullshit, reduced gaslighting and reduced manipulation in your life. In the end, you will help make our society a more open, fair place where the rules we live by can be openly discussed and negotiated by anybody regardless of who they are.</p>
                        <p>If you feel any of these rules need changing or want to talk about them, please feel free to discuss them with me:</p>
                        <p> https://www.facebook.com/edward.tanguay</p>
                    </div>
                </div>
            </div >
        );
    }
}

export default PageIfc;