import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/stylesPageMiniFrameworks.scss';
import * as qsys from 'datapod/qtools/qsys';
import * as qstr from 'datapod/qtools/qstr';
import * as qdat from 'datapod/qtools/qdat';
import { IMiniFramework, getMiniFrameworks } from 'custom/models/model_miniFrameworks';

const pageTitle = 'Mini Frameworks';

interface TheProps {
    changeSiteTitle: any
}
interface MainState {
    allItems: IMiniFramework[],
    filteredItems: IMiniFramework[],
    searchText: string
}

class MiniFrameworks extends Component<TheProps, MainState> {
    constructor(props: any) {
        super(props);

        this.state = {
            allItems: getMiniFrameworks(),
            filteredItems: [],
            searchText: ''
        };
    }

    componentDidMount() {
        const initialSort: any = (a: any, b: any) => a.systemWhenCreated > b.systemWhenCreated ? -1 : a.systemWhenCreated < b.systemWhenCreated ? 1 : 0;
        const filteredItems: IMiniFramework[] = this.state.allItems.sort(initialSort);

        this.setState({
            filteredItems
        });
        this.buildHowManyText(filteredItems.length);

        // act on URL parameters
        const id: number = qstr.forceStringAsInteger(qsys.getParameterValueFromUrl('id'));
        if (id !== 0) {
            this.displaySingleItem(id);
        } else {
            const searchText = qsys.getParameterValueFromUrl('searchText');
            if (!qstr.isEmpty(searchText)) {
                this.setState({
                    searchText
                });
                this.displaySearchResults(searchText);
            }
        }
    }

    displaySingleItem = (id: number) => {
        const filteredItems: IMiniFramework[] = this.state.allItems.filter((m: any) => m.id === id);
        this.setState({
            filteredItems
        });
        this.buildHowManyText(1);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    displayAll = () => {
        this.setState({
            searchText: '',
            filteredItems: this.state.allItems
        });
        qsys.changeBrowserState(document, 'miniFrameworks', '', '', pageTitle);
        this.props.changeSiteTitle(`${this.state.filteredItems.length} MiniFrameworks`);
    };

    getCurrentItem = (): IMiniFramework => {
        const item = this.state.filteredItems[0];
        qsys.changeBrowserState(document, 'miniFrameworks', 'id', String(item.id), `MINIFRAMEWORK: ${item.title}`);
        return item;
    };

    displaySearchResults = (searchText: string) => {
        const theFilteredItems: IMiniFramework[] = this.state.allItems.filter((m: any) => qstr.searchTextMatches(searchText, [m.title].join('|')));
        this.setState({
            searchText,
            filteredItems: theFilteredItems
        });
        if (qstr.isEmpty(searchText)) {
            qsys.changeBrowserState(document, 'miniFrameworks', '', '', pageTitle);
        } else {
            qsys.changeBrowserState(document, 'miniFrameworks', 'searchText', searchText, `Mini Frameworks Search: "${searchText}"`);
        }
        this.buildHowManyText(theFilteredItems.length);
    }

    searchKeywords = (event: any) => {
        const searchText: string = event.target.value;
        this.displaySearchResults(searchText);
    }

    convertDescriptionToDescriptionParsed = (miniFramework: IMiniFramework): string => {
        return qstr.parseOutline(miniFramework.description, 'miniFrameworks');
    }

    buildHowManyText(totalItemsShowing: number) {
        if (totalItemsShowing === this.state.allItems.length) {
            this.props.changeSiteTitle(`${totalItemsShowing} Mini Frameworks`);
        } else {
            this.props.changeSiteTitle(`${totalItemsShowing} of <a class="siteTitleLink" href="miniFrameworks">${this.state.allItems.length} Mini Frameworks</a>`);
        }
    }

    buildSmartItemLink(item: IMiniFramework) {
        return `miniFrameworks?id=${item.id}`;
    }

    render() {
        return (
            <div className="pageMiniFrameworks">
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>

                {/* SEARCH  */}
                {(this.state.filteredItems.length !== 1 || this.state.searchText !== '') && (
                    <div className="searchArea">
                        <div className="searchRow">
                            <input id="mainSearch" placeholder="search miniFrameworks" type="text" value={this.state.searchText} className="form-control input-sm searchBox" onFocus={this.searchKeywords} onChange={this.searchKeywords} />
                        </div>
                    </div>
                )}

                {/* ========== MULTIPLE RECORDS ========== */}
                {this.state.filteredItems.length > 1 &&
                    (
                        <div className="listItemMiniFrameworks">
                            {this.state.filteredItems.map((item: IMiniFramework, index: number) => {
                                return (
                                    <div key={index}>
                                        <div className="itemBox">
                                            <div className="header"><span className="createDate">{qdat.smartDateWithYear(item.systemWhenCreated)}</span> - <span className="idCode">{item.idCode}</span> - <span className="githubCode"><a href={item.url}>GitHub Code</a></span> - <span className="permalink"><a href={this.buildSmartItemLink(item)}>permalink</a></span> </div>
                                            <div className="title">{item.title}</div>
                                            {
                                                item.pathAndFileName && (
                                                    <div><img className="mainImage" src={item.pathAndFileName} alt={item.idCode} /></div>
                                                )
                                            }
                                            <div className="description" dangerouslySetInnerHTML={{ __html: this.convertDescriptionToDescriptionParsed(item) }} />
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div >
                    )
                }

                {/* ========== ONE RECORD ========== */}
                {
                    this.state.filteredItems.length === 1 &&
                    (
                        <div className="itemBox">
                            <div className="header"><span className="createDate">{qdat.smartDateWithYear(this.getCurrentItem().systemWhenCreated)}</span> - <span className="idCode">{this.getCurrentItem().idCode}</span> - <span className="githubCode"><a href={this.getCurrentItem().url}>GitHub Code</a></span></div>
                            <div className="title">{this.getCurrentItem().title}</div>
                            {
                                this.getCurrentItem().pathAndFileName && (
                                    <div><img className="mainImage" src={this.getCurrentItem().pathAndFileName} alt={this.getCurrentItem().idCode} /></div>
                                )
                            }
                            <div className="description" dangerouslySetInnerHTML={{ __html: this.convertDescriptionToDescriptionParsed(this.getCurrentItem()) }} />
                        </div>
                    )
                }

                {/* ========== NO RECORDS ========== */}
                {
                    this.state.filteredItems.length === 0 &&
                    (
                        <div>
                            Sorry, no items were found with this criteria.
                        </div>
                    )
                }

                {/* ========== NOT AUTHORIZED ========== */}
                {
                    false &&
                    (
                        <div className="alert alert-warning">
                            <strong>This page is no longer public.</strong>
                        </div>
                    )
                }
            </div >
        )
    }
}

export default MiniFrameworks;