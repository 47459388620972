import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import * as qstr from 'datapod/qtools/qstr';
import * as config from 'datapod/classes/config';
import 'custom/styles/main.scss';
import 'custom/styles/pageRigorousEthics.scss';
import pageRigorousEthicsData from 'custom/data/documentType_rigorousEthics.json';
import * as SystemTypes from 'datapod/classes/systemTypes';

const pageTitle = 'Rigorous Ethics';

interface TheProps {
	changeSiteTitle: any
}
interface MainState {
	smartPersons: any[],
	smartMinorPersons: any[],
	devMode: boolean,
	htmlDescription: string,
	htmlDescriptionFirstSentence: string,
	showFullDescription: boolean
}

class PageRigorousEthics extends Component<TheProps, MainState> {
	constructor(props: any) {
		super(props);
		this.state = {
			smartPersons: [],
			smartMinorPersons: [],
			devMode: false,
			htmlDescription: qstr.parseMarkDown(pageRigorousEthicsData.description),
			htmlDescriptionFirstSentence: qstr.parseMarkDown(pageRigorousEthicsData.descriptionFirstSentence, { suppressParagraphMarks: true, suppressOrderedListElements: false }),
			showFullDescription: false
		};
	}

	componentDidMount() {
		this.props.changeSiteTitle(pageRigorousEthicsData.title);
		const initialSort: any = (a: any, b: any) => a.rank > b.rank ? -1 : a.rank < b.rank ? 1 : 0;
		const smartPersons: any[] = pageRigorousEthicsData.persons.sort(initialSort);
		const smartMinorPersons: any[] = pageRigorousEthicsData.minorPersons.sort(initialSort);

		smartPersons.forEach(m => {
			m.htmlShortDescription = qstr.parseMarkDown(m.shortDescription, { suppressParagraphMarks: false, suppressOrderedListElements: false })
			m.htmlDescription = qstr.parseMarkDown(m.description, { suppressParagraphMarks: false, suppressOrderedListElements: false })
			if (config.currentLocalEnvironment() === SystemTypes.CurrentLocalEnvironment.hm) {
				m.imagePathAndFileName = `customImages/pageRigorousEthics/${m.idCode}.jpg`;
			} else {
				m.imagePathAndFileName = 'customImages/pageRigorousEthics/itemBlank.png';
			}
		});

		smartMinorPersons.forEach(m => {
			m.htmlDescription = qstr.parseMarkDown(m.description, { suppressParagraphMarks: true, suppressOrderedListElements: false })
		});

		this.setState({
			smartPersons,
			smartMinorPersons
		});
	}

	toggleMoreLessText() {
		this.setState({
			showFullDescription: !this.state.showFullDescription
		});
	}

	render() {
		return (
			<div className="pageRigorousEthics">
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
				<span className="descriptionFirstSentence" dangerouslySetInnerHTML={{ __html: this.state.htmlDescriptionFirstSentence }} />
				{!this.state.showFullDescription && (
					<span className="moreDescriptionLink"> [<span className="innerLink" onClick={() => this.toggleMoreLessText()}>more...</span>]</span>
				)}
				{this.state.showFullDescription && (
					<div className="description" dangerouslySetInnerHTML={{ __html: this.state.htmlDescription }} />
				)}
				<hr />
				<div className="persons">
					{this.state.smartPersons.map((person: any, index: number) => {
						return (
							<div className="personItem" key={index}>
								<div className="imageArea">
									<div><img src={person.imagePathAndFileName} alt="" /></div>
									<div className="infoBlock">
										{person.links.map((linkItem: any, linkIndex: number) => (
											<p key={linkIndex}><a href={linkItem.url}>{linkItem.name}</a></p>
										))}
									</div>
								</div>
								<div className="informationArea">
									<div className="fullName">{person.name}{this.state.devMode && (
										<span className="devModeText"> ({person.rank})</span>
									)}</div>

									{/* SELECTED PODCASTS */}
									{person.podcasts &&
										(
											<div className="selectedPodcasts"><span className="podcastLeader">Selected Podcasts:</span>
												{person.podcasts.map((podcast: any, podcastIndex: number) => (
													<span key={podcastIndex}><a className="podcastLink" href={podcast.url}>{podcast.title}</a>{podcastIndex < person.podcasts.length - 1 && ', '}</span>
												))}
											</div>
										)}
									<div className="shortDescription" dangerouslySetInnerHTML={{ __html: person.htmlShortDescription }} />
								</div>
							</div>
						)
					})}
				</div>

				{/* MINOR PERSONS */}
				<div className="minorPersons">
					<h1>Other people:</h1>
					{this.state.smartMinorPersons.map((person: any, index: number) => {
						return (
							<div className="minorPersonItem" key={index}>
								<span className="fullName">{person.name}{this.state.devMode && (
									<span className="devModeText"> ({person.rank})</span>
								)}</span>
								 &nbsp;- <span className="shortDescription">{person.shortDescription}</span> -&nbsp;
								<span className="description" dangerouslySetInnerHTML={{ __html: person.htmlDescription }} />
							</div>
						)
					})}
				</div>

				{/* TO RESEARCH */}
				<div className="researchItems">
					<h1>To research:</h1>
					<ul>
						{pageRigorousEthicsData.researchItems.map(term => (
							<li><a href={`https://google.com/search?q=${term}`} rel="noopener noreferrer" target="_blank">{term}</a></li>
						))}
					</ul>
				</div>

			</div>
		);
	}
}

export default PageRigorousEthics;