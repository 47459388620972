import quizItems from 'custom/data/items_quizItems.json';
import * as qstr from 'datapod/qtools/qstr';

function parseBody(body: string): [string, string[]] {
    const bodyLines = qstr.convertStringBlockToLines(body, false);
    const parsedBodyLines: string[] = [];
    const regex = /(~~.*?~~)/;
    const marker = '##1234567##';
    const answers: string[] = [];
    bodyLines.forEach(bodyLine => {
        let parsedBodyLine = bodyLine;
        parsedBodyLine = qstr.replaceAllRegex(bodyLine, regex, marker);
        const localAnswers: string[] = qstr.getArrayWithRegex(bodyLine, regex);
        if (localAnswers != null) {
            localAnswers.forEach(localAnswer => {
                answers.push(localAnswer);
            })
        }
        parsedBodyLines.push(parsedBodyLine);
    });

    // take "~~useState~~" to "useState"
    let finalAnswers: string[] = [];
    answers.forEach(answer => {
        finalAnswers.push(qstr.replaceAll(answer, '~~', ''));
    });
    finalAnswers = qstr.randomize(finalAnswers);

    const parsedBody = qstr.convertLinesToStringBlock(parsedBodyLines);
    let html: string = qstr.parseOutline(parsedBody, 'quizItems');
    html = qstr.replaceAll(html, marker, '<input type="text"/>');
    return [html, finalAnswers];
}
export interface IQuizItemRaw {
    id: number;
    category: string;
    description: string;
    body: string;
    extras: string;
    systemWhenCreated: string;
    systemWhoCreated: string;
}

export interface IQuizItem {
    id: number;
    category: string;
    description: string;
    body: string;
    extras: string;
    systemWhenCreated: string;
    systemWhoCreated: string;
    htmlBody: string;
    answers: string[];
    codeUrl: string;
}

export const buildQuizItem = (quizItemRaw: IQuizItemRaw) => {
    const [htmlBody, answers] = parseBody(quizItemRaw.body);
    return {
        id: quizItemRaw.id,
        category: quizItemRaw.category,
        description: quizItemRaw.description,
        body: quizItemRaw.body,
        extras: quizItemRaw.extras,
        systemWhenCreated: quizItemRaw.systemWhenCreated,
        systemWhoCreated: quizItemRaw.systemWhoCreated,
        htmlBody,
        answers,
        codeUrl: qstr.getExtrasValue(quizItemRaw.extras, 'code')
    }
}

export const getQuizItems = (): IQuizItem[] => {
    const items: IQuizItem[] = [];
    quizItems.forEach((itemRaw: IQuizItemRaw) => {
        const item: IQuizItem = buildQuizItem(itemRaw);
        items.push(item);
    });
    return items;
}