import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/stylesPageShowcaseGeneral.scss';

import showcaseBooks from 'custom/data/items_showcaseBooks.json';
import { IShowcaseBook } from 'custom/models/model_showcaseBooks';

import howtos from 'custom/data/items_howtos.json';
import { IHowto } from 'custom/models/model_howtos'; 

const pageTitle = 'Showcase General';

interface TheProps {
	changeSiteTitle: any
}
interface MainState {
	filteredShowcaseBooks: IShowcaseBook[],
	filteredHowtos: IHowto[]
}

class News extends Component<TheProps, MainState> {
	constructor(props: any) {
		super(props);

		this.state = {
			filteredShowcaseBooks: [],
			filteredHowtos: []
		};
	}

	componentDidMount() {
		this.props.changeSiteTitle('Showcase General');

		this.setState({
			filteredShowcaseBooks: showcaseBooks.slice(0, 2),
			filteredHowtos: howtos.slice(0, 10)
		})

	}

	render() {
		return (
			<div className="pageArea">
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
				<p>testing</p>
				<hr/>
				<p>Number of showcase books: {showcaseBooks.length}</p>
				<p>Number of filtered showcase books: {this.state.filteredShowcaseBooks.length}</p>
				<ul>
					{this.state.filteredShowcaseBooks.map((item: any) => {
						return (
							<li>{item.id}: {item.title} = {item.points}</li>
						)
					})}
				</ul>
				<hr />

				<p>Number of howtos: {howtos.length}</p>
				<p>Number of filtered howtos: {this.state.filteredHowtos.length}</p>
				<ul>
					{this.state.filteredHowtos.map((item: any) => {
						return (
							<li>{item.id}: {item.title} = {item.points}</li>
						)
					})}
				</ul>
			</div>
		);
	}
}

export default News;