/* eslint react/sort-comp: 0 */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/stylesPageTechItems.scss';
import * as qstr from 'datapod/qtools/qstr';
import * as qsys from 'datapod/qtools/qsys';
import * as config from 'datapod/classes/config';
import * as SystemTypes from 'datapod/classes/systemTypes';
import { ITechItem, getTechItems } from 'custom/models/model_techItems';
import { IComparison } from 'custom/models/model_comparisons';
import { ITechTopicGroup } from 'custom/models/model_techTopicGroups';
import { GoPencil } from 'react-icons/go';

const pageTitle = 'Technology';

interface TheProps {
    changeSiteTitle: any
}
interface MainState {
    allItems: ITechItem[],
    filteredItems: ITechItem[],
    searchText: string
}

class TechItems extends Component<TheProps, MainState> {
    constructor(props: any) {
        super(props);

        this.state = {
            allItems: getTechItems(),
            filteredItems: [],
            searchText: ''
        };
    }

    componentDidMount() {
        const initialSort: any = (a: any, b: any) => a.systemWhenCreated > b.systemWhenCreated ? -1 : a.systemWhenCreated < b.systemWhenCreated ? 1 : 0;
        const filteredItems: ITechItem[] = this.state.allItems.sort(initialSort);
        this.setState({
            filteredItems
        });
        this.buildHowManyText(filteredItems.length);

        // act on URL parameters
        const id: number = qstr.forceStringAsInteger(qsys.getParameterValueFromUrl('id'));
        if (id !== 0) {
            this.displaySingleItem(id);
        } else {
            const searchText = qsys.getParameterValueFromUrl('searchText');
            if (!qstr.isEmpty(searchText)) {
                this.setState({
                    searchText
                });
                this.displaySearchResults(searchText);
            }
        }
    }

    displaySingleItem = (id: number) => {
        const filteredItems: ITechItem[] = this.state.allItems.filter((m: any) => m.id === id);
        this.setState({
            filteredItems
        });
        this.buildHowManyText(1);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    displayAll = () => {
        this.setState({
            searchText: '',
            filteredItems: this.state.allItems
        });
        qsys.changeBrowserState(document, 'techItems', '', '', pageTitle);
        this.props.changeSiteTitle(`${this.state.filteredItems.length} Tech Items`);
    };

    getCurrentItem = (): ITechItem => {
        const item = this.state.filteredItems[0];
        qsys.changeBrowserState(document, 'techItems', 'id', String(item.id), `${item.genericTermIdCode.toUpperCase()} TECH ITEM: ${item.title}`); return item;
    };

    displaySearchResults = (searchText: string) => {
        const theFilteredItems: ITechItem[] = this.state.allItems.filter((m: any) => qstr.searchTextMatches(searchText, [m.title, m.body, m.quick].join('|')));
        this.setState({
            searchText,
            filteredItems: theFilteredItems
        });
        if (qstr.isEmpty(searchText)) {
            qsys.changeBrowserState(document, 'techItems', '', '', pageTitle);
        } else {
            qsys.changeBrowserState(document, 'techItems', 'searchText', searchText, `Tech Search: "${searchText}"`);
        }
        this.buildHowManyText(theFilteredItems.length);
    }

    searchKeywords = (event: any) => {
        const searchText: string = event.target.value;
        this.displaySearchResults(searchText);
    }

    buildSmartItemLink(techItem: ITechItem) {
        return `techItems?id=${techItem.id}`;
    }

    buildHowManyText(totalItemsShowing: number) {
        if (totalItemsShowing === this.state.allItems.length) {
            this.props.changeSiteTitle(`${totalItemsShowing} Tech Items`);
        } else {
            this.props.changeSiteTitle(`${totalItemsShowing} of <a class="siteTitleLink" href="techItems">${this.state.allItems.length} Tech Items</a>`);
        }
    }

    convertTechItemBodyToBodyParsed = (techItem: ITechItem): string => {
        return qstr.parseOutline(techItem.body, 'techItems');
    }

    getComparisonItemTypeIdCodes(comparison: IComparison): ITechItem[] {
        const techItemIdCodes = qstr.breakIntoParts(comparison.techItemList, ',');
        return this.state.allItems.filter((techItem: ITechItem) => techItemIdCodes.includes(techItem.idCode!));
    }

    getSmartVersusLine(comparison: IComparison, currentTechItem: ITechItem): string {
        const smartLinks: string[] = this.getComparisonItemTypeIdCodes(comparison).map((techItem: ITechItem) => {
            let r = '';
            if (techItem.id === currentTechItem.id) {
                // r += `<span class="currentTechItem">${techItem.title}</span>`;
                r += `<a class="relatedTechItem" href="techItems?id=${techItem.id}">${techItem.title}</a>`;
            } else {
                r += `<a class="relatedTechItem" href="techItems?id=${techItem.id}">${techItem.title}</a>`;
            }
            return r;
        });
        return smartLinks.join(' <span class="versus">vs.</span> ');
    }

    convertComparisonBodyToBodyParsed = (comparison: IComparison): string => {
        return qstr.parseOutline(comparison.body, 'comparisons');
    }

    convertTechTopicGroupDescriptionToBodyParsed = (techTopicGroup: ITechTopicGroup): string => {
        return qstr.parseOutline(techTopicGroup.description, 'techTopicGroup');
    }
    render() {
        return (
            <div className="pageTech">
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>

                {/* SEARCH  */}
                {(this.state.filteredItems.length !== 1 || this.state.searchText !== '') && (
                    <div className="searchArea">
                        <div className="searchRow">
                            <input id="mainSearch" placeholder="search tech items" type="text" value={this.state.searchText} className="form-control input-sm searchBox" onFocus={this.searchKeywords} onChange={this.searchKeywords} />
                        </div>
                    </div>
                )}

                {/* ========== MULTIPLE RECORDS ========== */}
                {this.state.filteredItems.length > 1 &&
                    (
                        <div className="listTechItems">
                            {this.state.filteredItems.map((item: ITechItem, index: number) => {
                                return (
                                    <div key={index} className="overview">
                                        <a href={this.buildSmartItemLink(item)}>
                                            <div className="body">
                                                <div className="header"> <span className="genericTermIdCode">{item.genericTermIdCode}</span></div>
                                                <div className="title">{item.title}</div>
                                                <div className="quick">{item.quick}</div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    )
                }

                {/* ========== ONE RECORD ========== */}
                {
                    this.state.filteredItems.length === 1 &&
                    (
                        <div className="item">
                            <div className="header">
                                <div className="category">{this.getCurrentItem().genericTermIdCode}</div>
                                <div className="title"><span>{this.getCurrentItem().title}</span>
                                    {
                                        config.currentApplicationLocation() === SystemTypes.ApplicationLocation.local &&
                                        (
                                            <a target="_blank" rel="noopener noreferrer" href={`http://localhost:29900/manageTechItem?returnUrl=techItems%C2%A7id=${this.getCurrentItem().id}&command=edit&id=${this.getCurrentItem().id}`}><GoPencil className="editIcon" /></a>
                                        )
                                    }
                                </div>
                                <div className="quick">{this.getCurrentItem().quick}</div>
                            </div>
                            <div className="body">
                                <div className="codeArea" dangerouslySetInnerHTML={{ __html: this.convertTechItemBodyToBodyParsed(this.getCurrentItem()) }} />
                            </div>


                            {/* COMPARISONS */}
                            {this.getCurrentItem().comparisons!.length > 0 &&
                                (
                                    <div className="comparisonArea">
                                        {this.getCurrentItem().comparisons!.map((comparison: IComparison, index: number) => {
                                            return (
                                                <div className="comparison" key={index}>
                                                    <h3 className="title" dangerouslySetInnerHTML={{ __html: this.getSmartVersusLine(comparison, this.getCurrentItem()) }} />
                                                    <div className="comparisonBodyArea" dangerouslySetInnerHTML={{ __html: this.convertComparisonBodyToBodyParsed(comparison) }} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }

                            {/* TECHTOPICGROUPS */}
                            {this.getCurrentItem().techTopicGroups!.length > 0 &&
                                (
                                    <div className="techTopicGroupArea">
                                        {this.getCurrentItem().techTopicGroups!.map((techTopicGroup: ITechTopicGroup, index: number) => {
                                            return (
                                                <div className="techTopicGroup" key={index}>
                                                    <h3 className="title">{techTopicGroup.title}</h3>
                                                    <div className="groupBody" dangerouslySetInnerHTML={{ __html: this.convertTechTopicGroupDescriptionToBodyParsed(techTopicGroup) }} />
                                                    <img className="techTopicGroupImage" src={`customImages/techTopicGroups/techTopicGroup_${techTopicGroup.idCode}.png`} alt="" />
                                                    <ul className="techItemsList">
                                                        {techTopicGroup.techItems?.map((techItem: ITechItem, techItemIndex: number) => {
                                                            return (
                                                                <li key={techItemIndex}><a className="techItemLink" href={`techItems?id=${techItem.id}`}>{techItem.title}</a> - <span className="quick">{techItem.quick}</span> </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                {/* ========== NO RECORDS ========== */}
                {
                    this.state.filteredItems.length === 0 &&
                    (
                        <div>
                            Sorry, no items were found with this criteria.
                        </div>
                    )
                }

                {/* ========== NOT AUTHORIZED ========== */}
                {
                    false &&
                    (
                        <div className="alert alert-warning">
                            <strong>This page is no longer public.</strong>
                        </div>
                    )
                }
            </div >
        );
    }
}

export default TechItems;