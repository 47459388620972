import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';

const pageTitle = 'Intellectual Dark Web';

interface TheProps {
	changeSiteTitle: any
}
class PageIntellectualDarkWeb extends Component<TheProps> {
	constructor(props: any) {
		super(props);
		this.props.changeSiteTitle('Intellectual Dark Web');
	}

	render() {
		return (
			<div className="pageIntellectualDarkWeb">
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
				<div>This page has moved <a href="rigorousEthics">here</a>.</div>
			</div>
		);
	}
}

export default PageIntellectualDarkWeb;