import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/stylesPageFlashcards.scss';
import * as qstr from 'datapod/qtools/qstr';

const items = require('custom/data/items_flashcards.json');

const pageTitle = 'Flashcards';
const cardBack = require('custom/images/cardBack.png');

interface CategoryItem {
	label: string,
	idCode: string,
	className: string
}

interface MainState {
	filteredItems: any[],
	searchText: string,
	categories: CategoryItem[]
}

class Flashcards extends Component<{}, MainState> {
	constructor(props: any) {
		super(props);

		const categories = [...new Set(items.map((m: any) => m.category))].map((n: any) => ({ 'label': n, 'idCode': n, 'className': 'inactive' }));

		this.state = {
			filteredItems: [],
			searchText: '',
			categories
		};
	}

	componentDidMount() {
		this.setState({
			filteredItems: items.filter((m: any) => !m.back.includes('```') && !m.back.includes('http'))
		});
	}

	showItemsInCategory = (category: CategoryItem) => {
		const newCategories = this.state.categories.map(m => {
			m.className = 'inactive';
			return m;
		});
		this.setState({
			filteredItems: items.filter((m: any) => m.category.includes(category.idCode)),
			categories: newCategories
		});
		category.className = 'active';
	};

	searchKeywords = (event: any) => {
		const searchText: string = event.target.value;
		this.setState({
			searchText,
			filteredItems: items.filter((m: any) => m.front.toUpperCase().includes(searchText.toUpperCase()) || m.back.toUpperCase().includes(searchText.toUpperCase()))
		});
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
				<div className="alert alert-warning" role="alert">
					<strong>2020-10-28:</strong> This page is under construction, but you can already search and find flashcards based on (one) keyword.</div>
				<div className="searchArea">
					<div className="searchRow">
						<input type="text" value={this.state.searchText} className="form-control input-sm searchBox" onChange={this.searchKeywords} />
					</div>
					<div className="buttonRow">
						{this.state.categories.map(item => {
							return (
								<span>
									<span className={`category ${item.className}`} onClick={() => this.showItemsInCategory(item)}>{item.label}</span><span className="separator"> |&nbsp;</span>
								</span>
							)
						})}
					</div>
				</div>
				<h2 className="pageTitle">Flashcards</h2>
				{this.state.filteredItems.map(item => {
					return (
						<div className="flashcard">
							<div className="cardArea">
								<img src={cardBack} className="icon" alt="greybox" />
								<div>{item.state}</div>
							</div>
							<div className="infoArea">
								<div className="categoryKind"><span className="category">{item.category}</span> {qstr.capitalizeFirstLetter(item.kind)} </div>
								<div className="front">{qstr.capitalizeFirstLetter(item.front)}</div>
								<div className="back"><span>{item.back}</span></div>
							</div>
						</div>
					)
				})}
			</div>
		);
	}
}

export default Flashcards;