import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/stylesPageHowtos.scss';
import * as qsys from 'datapod/qtools/qsys';
import * as qstr from 'datapod/qtools/qstr';
import * as qdat from 'datapod/qtools/qdat';
import howtos from 'custom/data/items_howtos.json';
import { IHowto } from 'custom/models/model_howtos';
import { FaEye } from 'react-icons/fa';
import { MdFileDownload } from 'react-icons/md';

const pageTitle = 'Howtos';

interface TheProps {
	changeSiteTitle: any
}
interface MainState {
	filteredItems: IHowto[],
	searchText: string
}

class Howtos extends Component<TheProps, MainState> {
	constructor(props: any) {
		super(props);

		this.state = {
			filteredItems: [],
			searchText: ''
		};
	}

	componentDidMount() {
		this.prepareItems();
		const initialSort: any = (a: any, b: any) => a.systemWhenCreated > b.systemWhenCreated ? -1 : a.systemWhenCreated < b.systemWhenCreated ? 1 : 0;
		const filteredItems: IHowto[] = howtos.sort(initialSort);

		this.setState({
			filteredItems
		});
		this.buildHowManyText(filteredItems.length);

		// act on URL parameters
		const id: number = qstr.forceStringAsInteger(qsys.getParameterValueFromUrl('id'));
		if (id !== 0) {
			this.displaySingleItem(id);
		} else {
			const searchText = qsys.getParameterValueFromUrl('searchText');
			if (!qstr.isEmpty(searchText)) {
				this.setState({
					searchText
				});
				this.displaySearchResults(searchText);
			}
		}
	}

	getExampleLinkHtml(item: IHowto): string {
		const { idCode } = item;
		if (idCode === '') {
			return '';
		}
		return `${String(idCode)} this is <b>bold</b>[<FaEye/>]`;
	}

	getDownloadLinkHtml(item: IHowto): string {
		const { download } = item;
		if (download === '') {
			return '';
		}
		return `${String(download)} this is <b>bold</b>[<FaEye/>]`;
	}

	displaySingleItem = (id: number) => {
		const filteredItems: IHowto[] = howtos.filter((m: any) => m.id === id);
		this.setState({
			filteredItems
		});
		this.buildHowManyText(1);
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	};

	displayAll = () => {
		this.setState({
			searchText: '',
			filteredItems: howtos
		});
		qsys.changeBrowserState(document, 'howtos', '', '', pageTitle);
		this.props.changeSiteTitle(`${this.state.filteredItems.length} Howtos`);
	};

	getCurrentItem = (): IHowto => {
		const item = this.state.filteredItems[0];
		qsys.changeBrowserState(document, 'howtos', 'id', String(item.id), `${item.category.toUpperCase()} HOWTO: ${item.title}`);
		return item;
	};

	displaySearchResults = (searchText: string) => {
		const theFilteredItems: IHowto[] = howtos.filter((m: any) => qstr.searchTextMatches(searchText, [m.title, m.body].join('|')));
		this.setState({
			searchText,
			filteredItems: theFilteredItems
		});
		if (qstr.isEmpty(searchText)) {
			qsys.changeBrowserState(document, 'howtos', '', '', pageTitle);
		} else {
			qsys.changeBrowserState(document, 'howtos', 'searchText', searchText, `Howtos Search: "${searchText}"`);
		}
		this.buildHowManyText(theFilteredItems.length);
	}

	searchKeywords = (event: any) => {
		const searchText: string = event.target.value;
		this.displaySearchResults(searchText);
	}

	convertBodyToBodyParsed = (howto: IHowto): string => {
		return qstr.parseOutline(howto.body, 'howtos');
	}


	prepareItems() {
		howtos.forEach((item: IHowto) => {
			item.idCode = qstr.getExtrasValue(item.extras, 'idCode');
			item.download = qstr.getExtrasValue(item.extras, 'download');
			item.jsfiddle = qstr.getExtrasValue(item.extras, 'jsfiddle');
			item.jsfiddleUrl = `//jsfiddle.net/edwardtanguay/${item.jsfiddle}/embedded/js,html,css,result/dark/`;
		});
	}


	buildHowManyText(totalItemsShowing: number) {
		if (totalItemsShowing === howtos.length) {
			this.props.changeSiteTitle(`${totalItemsShowing} Howtos`);
		} else {
			this.props.changeSiteTitle(`${totalItemsShowing} of <a class="siteTitleLink" href="howtos">${howtos.length} Howtos</a>`);
		}
	}

	render() {
		return (
			<div className="pageHowtos">
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>

				<div className="siteMessage">
					<strong>NOTE:</strong> These Howtos are no longer being regularly updated. For my newest Howtos, please visit: <a href="https://edwardtanguay.vercel.app/howtos">edwardtanguay.vercel.app/howtos</a>
				</div>

				{/* SEARCH  */}
				{(this.state.filteredItems.length !== 1 || this.state.searchText !== '') && (
					<div className="searchArea">
						<div className="searchRow">
							<input id="mainSearch" placeholder="search howtos" type="text" value={this.state.searchText} className="form-control input-sm searchBox" onFocus={this.searchKeywords} onChange={this.searchKeywords} />
						</div>
					</div>
				)}

				{/* ========== MULTIPLE RECORDS ========== */}
				{this.state.filteredItems.length > 1 &&
					(
						<div className="listItemHowtos">
							{this.state.filteredItems.map((item: IHowto, index: number) => {
								return (
									<div key={index}>
										<div className="header"><span className="createDate">{qdat.smartDateWithYear(item.systemWhenCreated)}</span> <span className="category">{item.category}</span></div>
										<div className="body"><span onClick={() => this.displaySingleItem(item.id)}>{item.title}</span></div>
									</div>
								)
							})}
						</div>
					)
				}

				{/* ========== ONE RECORD ========== */}
				{this.state.filteredItems.length === 1 &&
					(
						<div className="item">
							<div className="header">
								<div><span className="createDate">{qdat.smartDateWithYear(this.getCurrentItem().systemWhenCreated)}</span> <span className="category">{this.getCurrentItem().category}</span></div>
								<div className="headerRow">
									<div className="title">{this.getCurrentItem().title}</div>
									<div className="subRow">
										{this.getExampleLinkHtml(this.getCurrentItem()) !== '' && (
											<a href={`examples/howtos/${this.getCurrentItem().idCode}/index.html`} target="_blank" rel="noopener noreferrer"><div className="showExample"><div><FaEye /></div> <div className="text">Example</div></div></a>
										)}
										{this.getDownloadLinkHtml(this.getCurrentItem()) !== '' && (
											<a href={`downloads/howtos/${this.getCurrentItem().idCode}.zip`} target="_blank" rel="noopener noreferrer"><div className="showDownload"><div><MdFileDownload /></div> <div className="text">Download</div></div></a>
										)}
									</div>
								</div>
							</div>
							<div className="body">
								<div className="codeArea" dangerouslySetInnerHTML={{ __html: this.convertBodyToBodyParsed(this.getCurrentItem()) }} />
							</div>
							{this.getCurrentItem().jsfiddle &&
								(
									<div className="jsfiddleWrapper">
										<div className="title">jsfiddle:</div>
										<iframe title="JSFiddle" src={this.getCurrentItem().jsfiddleUrl} className="jsfiddle" width="100%" height="300" />
									</div>
								)
							}
						</div>
					)
				}

				{/* ========== NO RECORDS ========== */}
				{this.state.filteredItems.length === 0 &&
					(
						<div>
							Sorry, no items were found with this criteria.
						</div>
					)
				}

				{/* ========== NOT AUTHORIZED ========== */}
				{false &&
					(
						<div className="alert alert-warning">
							<strong>This page is no longer public.</strong>
						</div>
					)
				}
			</div>
		);
	}
}

export default Howtos;