import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/stylesPageFitnessDays.scss';
import { IFitnessDay, getFitnessDays } from 'custom/models/model_fitnessDays'

const pageTitle = 'FitnessDays';

interface TheProps {
    changeSiteTitle: any
}
interface MainState {
    allItems: IFitnessDay[],
    filteredItems: IFitnessDay[]
}

class FitnessDays extends Component<TheProps, MainState> {
    constructor(props: any) {
        super(props);

        this.state = {
            allItems: getFitnessDays(),
            filteredItems: []
        };
    }

    componentDidMount() {
        const initialSort: any = (a: any, b: any) => a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
        const filteredItems: IFitnessDay[] = this.state.allItems.sort(initialSort);

        this.setState({
            filteredItems
        });
        this.props.changeSiteTitle('Fitness');
    }

    displaySingleItem = (id: number) => {
        const filteredItems: IFitnessDay[] = this.state.allItems.filter((m: any) => m.id === id);
        this.setState({
            filteredItems
        });
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    render() {
        return (
            <div className="pageFitnessDays">
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>

                {/* ========== MULTIPLE RECORDS ========== */}
                {this.state.filteredItems.length > 1 &&
                    (
                        <div className="listItemFitnessDays">
                            <h1>Edward's Starve-Enjoy-Workout Fitness Plan</h1>
                            <ul>
                                <li>Mon-Thu = Starve Days = eat only lunch, otherwise black coffee, tea, carbonated lemon water, cold water, warm water</li>
                                <li>Fri = Enjoy Day = eat anything you want, as much as you want, fun!</li>
                                <li>Sat-Sun = Normal Days = eat normal breakfast, lunch, dinner, lightly snack, etc.</li>
                                <li>fitness = do a run or a youtube workout (20-45 min) so that your muscles are sore the next day(s), repeat when they recover</li>
                                <li>Guaranteed weight loss: 1 kilo per week until you reach your BMI</li>
                            </ul>
                            <h2>2021 Fitness Tracking</h2>
                            {this.state.filteredItems.map((item: IFitnessDay, index: number) => {
                                return (
                                    <div key={index}>
                                        <div>{item.weekDayAndYear} - <span className="weight">{item.weight}</span> - <span className="eat">{item.eat}</span> - <span className="workout">{item.workout}</span></div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                }


                {/* ========== NO RECORDS ========== */}
                {this.state.filteredItems.length === 0 &&
                    (
                        <div>
                            Sorry, no items were found with this criteria.
                        </div>
                    )
                }

                {/* ========== NOT AUTHORIZED ========== */}
                {false &&
                    (
                        <div className="alert alert-warning">
                            <strong>This page is no longer public.</strong>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default FitnessDays;
