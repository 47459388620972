import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import 'datapod/styles/managePages.scss';
import 'custom/styles/stylesPageManageQuotes.scss';
import $ from 'jquery';
import * as qsys from 'datapod/qtools/qsys';
import * as qstr from 'datapod/qtools/qstr';
import * as config from 'datapod/classes/config';

const items = require('custom/data/items_quotes.json');

const pageTitle = 'Manage Quotes';

interface MainState {
	filteredItems: any[],
	searchText: string,
	showIdColumn: boolean,
	showTestingArea: boolean
}

class ManageQuotes extends Component<{}, MainState> {
	constructor(props: any) {
		super(props);

		this.state = {
			filteredItems: [],
			searchText: '',
			showIdColumn: false,
			showTestingArea: false
		};

		this.toggleColumnId = this.toggleColumnId.bind(this);
		this.toggleTestingArea = this.toggleTestingArea.bind(this);
	}


	componentDidMount() {
		const descendingRank: any = (a: any, b: any) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
		const filteredItems = items.sort(descendingRank);
		this.setState({
			filteredItems
		});
		$('#mainSearch').focus();

		// act on URL parameters
		const id: number = qstr.forceStringAsInteger(qsys.getParameterValueFromUrl('id'));
		if (id !== 0) {
			this.displaySingleItem(id);
		} else {
			const searchText = qsys.getParameterValueFromUrl('searchText');
			if (!qstr.isEmpty(searchText)) {
				this.setState({
					searchText
				});
				this.displaySearchResults(searchText);
			}
		}
	}

	searchKeywords = (event: any) => {
		const searchText: string = event.target.value;
		this.displaySearchResults(searchText);
	}

	displaySearchResults = (searchText: string) => {
		this.setState({
			searchText,
			filteredItems: items.filter((m: any) => m.text.toUpperCase().includes(searchText.toUpperCase()) ||
				m.category.toUpperCase().includes(searchText.toUpperCase()))
		});
		if (qstr.isEmpty(searchText)) {
			qsys.changeBrowserState(document, 'manageQuotes', '', '', pageTitle);
		} else {
			qsys.changeBrowserState(document, 'manageQuotes', 'searchText', searchText, `Quotes Search: "${searchText}"`);
		}
	}

	displaySingleItem = (id: number) => {
		const filteredItems = items.filter((m: any) => m.id === id);
		this.setState({
			searchText: '',
			filteredItems
		});
	};

	displayAll = () => {
		this.setState({
			searchText: '',
			filteredItems: items
		});
		qsys.changeBrowserState(document, 'manageQuotes', '', '', pageTitle);
	};

	getCurrentItem = () => {
		if (this.state.filteredItems.length === 0) {
			return {};
		}
		const item = this.state.filteredItems[0];
		qsys.changeBrowserState(document, 'manageQuotes', 'id', item.id, `Quote: ${item.text}`);
		return item;

	};


	toggleColumnId() {
		this.setState((prevState) => ({
			showIdColumn: !prevState.showIdColumn
		}));
	}

	toggleTestingArea() {
		this.setState((prevState) => ({
			showTestingArea: !prevState.showTestingArea
		}));
	}	

	render() {
		return (
			<div>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>

				{/* ========== TOP AREA ========== */}
				{config.siteIsLocal() &&
					(
						<div>
							<div className="searchArea">
								<div className="searchRow">
									<input id="mainSearch" type="text" value={this.state.searchText} className="form-control input-sm searchBox" onChange={this.searchKeywords} />
								</div>
							</div>
						<h2 className="pageTitle"><span onClick={() => this.displayAll()}>Manage Quotes</span></h2>
						<div>
							<span className="toggleId prohibitSelectionClickable" onClick={this.toggleColumnId}>Toggle ID</span> |&nbsp;
							<span className="toggleId prohibitSelectionClickable" onClick={this.toggleTestingArea}>Testing Area</span>
						</div>
						<div className={`exampleArea ${this.state.showTestingArea ? 'elementShow' : 'elementHide'}`}>
							<h1>Testing Area</h1>
						</div>
						</div>
					)
				}

				{/* ========== MULTIPLE RECORDS ========== */}
				{config.siteIsLocal() && this.state.filteredItems.length > 1 &&
					(
						<div className="manageContainer">
							<div className="manageTable">
								<table>
									<thead>
										<tr>
											<th className={this.state.showIdColumn ? 'elementShow' : 'elementHide'}>ID</th>
											<th>Category</th>
											<th>Text</th>
											<th>Rank</th>
											<th>Status</th>
											<th>systemWhenCreated</th>
											<th>systemWhoCreated</th>
										</tr>
									</thead>
									<tbody>
										{this.state.filteredItems.map((item, index) => {
											return (
												<tr key={index} onClick={() => this.displaySingleItem(item.id)}>
													<td className={this.state.showIdColumn ? 'elementShow' : 'elementHide'}>{item.id}</td>
													<td>{item.category}</td>
													<td className="dpodTypeParagraph">{item.text}</td>
													<td>{item.rank}</td>
													<td>{item.status}</td>
													<td>{item.systemWhenCreated}</td>
													<td>{item.systemWhoCreated}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					)
				}

				{/* ========== ONE RECORD ========== */}
				{config.siteIsLocal() && this.state.filteredItems.length === 1 &&
					(
						<div className="card manageItemCard mt-4">
							<div className="card-header">Quote</div>
							<div className="card-body manageItemBody">
								<dl>
									<dt>ID</dt>
									<dd>{this.getCurrentItem().id}</dd>
								</dl>
								<dl>
									<dt>Category</dt>
									<dd>{this.getCurrentItem().category}</dd>
								</dl>
								<dl>
									<dt>Text</dt>
									<dd>{this.getCurrentItem().text}</dd>
								</dl>
								<dl>
									<dt>Rank</dt>
									<dd>{this.getCurrentItem().rank}</dd>
								</dl>
								<dl>
									<dt>Status</dt>
									<dd>{this.getCurrentItem().status}</dd>
								</dl>
								<dl>
									<dt>System When Created</dt>
									<dd>{this.getCurrentItem().systemWhenCreated}</dd>
								</dl>
								<dl>
									<dt>Sytem Who Created</dt>
									<dd>{this.getCurrentItem().systemWhoCreated}</dd>
								</dl>
							</div>
						</div>
					)
				}

				{/* ========== NO RECORDS ========== */}
				{config.siteIsLocal() && this.state.filteredItems.length === 0 &&
					(
						<div>
							Sorry, no items were found with this criteria.
						</div>
					)
				}

				{/* ========== NOT AUTHORIZED ========== */}
				{!config.siteIsLocal() &&
					(
						<div className="alert alert-warning">
							<strong>This page is no longer public.</strong> Check out my tech <a href="howtos">howtos</a> instead.
						</div>
					)
				}
			</div>
		);
	}
}

export default ManageQuotes;