import webDevCourseJson from 'custom/data/documentType_webDevCourse.json';
import { getCodeExercises } from 'custom/models/model_codeExercises';
import { getTechItems, ITechItem } from 'custom/models/model_techItems';
import moment from 'moment';

let currentDay = moment(new Date('2021-03-01'));

const codeExercises = getCodeExercises();
const techItems = getTechItems();

export const getUnits = (): any[] => {
    const webDevCourse = JSON.parse(JSON.stringify(webDevCourseJson));
    const units: any = webDevCourse.units.map((unit: any, unitIndex: number) => {
        const unitCount = unitIndex + 1;
        unit.title = `${unitCount}. ${unit.title}`;
        unit.subunits = unit.subunits.map((subunit: any, subunitIndex: number) => {
            const subunitCount = subunitIndex + 1;
            subunit.title = `${unitCount}.${subunitCount}. ${subunit.title}`;
            subunit.bulkSearchText = '';
            subunit.sections = subunit.sections.map((section: any) => {
                const sectionItems: any[] = [];

                // formatted days
                if (section.days) {
                    section.formattedDay = currentDay.format('dddd, MMMM DD, YYYY');
                    currentDay = currentDay.add(currentDay.weekday() === 4 ? 4 : 1, 'days');
                }
                // techItems
                techItems.forEach((techItem: ITechItem) => {
                    if (techItem.webdev === section.idCode) {
                        sectionItems.push({
                            kindName: 'Tech Item',
                            title: techItem.title,
                            url: `techItems?id=${techItem.id}`
                        });
                    }
                })

                // codeExercises
                codeExercises.forEach((codeExercise: any) => {
                    if (codeExercise.webdev === section.idCode) {
                        sectionItems.push({
                            kindName: 'Code Exercise',
                            title: codeExercise.title,
                            url: `codeExercises?id=${codeExercise.id}`
                        });
                    }
                })
                // section.sectionItems = sectionItems;
                section.sectionItems = [];

                // record bulk search text for searching
                const bulkSearchText = `${section.title}|${section.overview}`;
                section.bulkSearchText = bulkSearchText;
                subunit.bulkSearchText += section.bulkSearchText;

                return section;
            });
            return subunit;
        })
        return unit;
    })
    return units;
}