import React, { Component } from 'react';
import {
    Route,
    NavLink,
    BrowserRouter
} from 'react-router-dom';
import 'datapod/styles/reset.scss';
import 'datapod/styles/site.scss';

import Home from 'custom/components/pageHome';
import ShowcaseGeneral from 'custom/components/pageShowcaseGeneral';
import Flashcards from 'custom/components/pageFlashcards';
import Quotes from 'custom/components/pageQuotes';
import ManageQuotes from 'custom/components/pageManageQuotes';
import Howtos from 'custom/components/pageHowtos';
import Forays from 'custom/components/pageForays';
import FitnessDays from 'custom/components/pageFitnessDays';
import MiniFrameworks from 'custom/components/pageMiniFrameworks';
import CodeExercises from 'custom/components/pageCodeExercises';
import QuizItems from 'custom/components/pageQuizItems';
import WebDevCourse from 'custom/components/pageWebDevCourse';
import About from 'custom/components/pageAbout';

import ScrollToTop from 'datapod/components/scrollToTop';
// DPODMARKER_addPageImport
import PageShowcaseFlexPictureCards from 'datapod/components/pageShowcaseFlexPictureCards';
import PageIntellectualDarkWeb from 'custom/components/pageIntellectualDarkWeb';
import PageRigorousEthics from 'custom/components/pageRigorousEthics';
import PageIfc from 'custom/components/pageIfc';
import PageTechItems from 'custom/components/pageTechItems';
import PageHbb from 'custom/components/pageHbb';

import * as SystemTypes from 'datapod/classes/systemTypes';
import * as config from 'datapod/classes/config';

import { BsHouse } from 'react-icons/bs';

const siteTitle = '<a href="about">Edward Tanguay</a> - All My Projects';

interface MainState {
    showSiteMenuArea: boolean,
    showSiteSearchArea: boolean,
    menuItems: any,
    siteTitle: string,
    pageProps: any
}

class Main extends Component<{}, MainState> {

    constructor(props: any) {
        super(props);

        this.state = {
            showSiteMenuArea: false,
            showSiteSearchArea: false,
            menuItems: [
                {
                    idCode: 'howtos',
                    title: 'Howtos',
                    location: SystemTypes.ApplicationLocation.public
                },
                {
                    idCode: 'flashcards',
                    title: 'Flashcards',
                    location: SystemTypes.ApplicationLocation.public
                },
                // DPODMARKER_addPageMenuItem
                {
                    idCode: 'intellectualDarkWeb',
                    title: 'Intellectual Dark Web',
                    location: SystemTypes.ApplicationLocation.local
                },
                {
                    idCode: 'rigorousEthics',
                    title: 'Rigorous Ethics',
                    location: SystemTypes.ApplicationLocation.local
                },
                {
                    idCode: 'ifc',
                    title: 'Intellectual Fight Club',
                    location: SystemTypes.ApplicationLocation.public
                },
                {
                    idCode: 'hbb',
                    title: 'High-Bar Internet Browsing',
                    location: SystemTypes.ApplicationLocation.public
                },
                {
                    idCode: 'techItems',
                    title: 'Tech Items',
                    location: SystemTypes.ApplicationLocation.public
                },
                {
                    idCode: 'showcaseFlexPictureCards',
                    title: 'Showcase: Flex Picture Cards',
                    location: SystemTypes.ApplicationLocation.local
                },
                {
                    idCode: 'showcaseGeneral',
                    title: 'Showcase General',
                    location: SystemTypes.ApplicationLocation.local
                },
                {
                    idCode: 'forays',
                    title: 'Forays',
                    location: SystemTypes.ApplicationLocation.public
                },
                {
                    idCode: 'fitnessDays',
                    title: 'Fitness',
                    location: SystemTypes.ApplicationLocation.local
                },
                {
                    idCode: 'miniFrameworks',
                    title: 'Mini Frameworks',
                    location: SystemTypes.ApplicationLocation.public
                },
                {
                    idCode: 'codeExercises',
                    title: 'Code Exercises',
                    location: SystemTypes.ApplicationLocation.public
                },
                {
                    idCode: 'quizItems',
                    title: 'Quiz Items',
                    location: SystemTypes.ApplicationLocation.public
                },
                {
                    idCode: 'webDevCourse',
                    title: 'Web Dev Course',
                    location: SystemTypes.ApplicationLocation.public
                },
                {
                    idCode: 'about',
                    title: 'About This Site',
                    location: SystemTypes.ApplicationLocation.public
                }
            ],
            siteTitle,
            pageProps: {
                changeSiteTitle: this.changeSiteTitle,
                displayGroup: 'all'
            }
        };

        // this.toggleSiteMenuArea = this.toggleSiteMenuArea.bind(this);
        // this.hideSiteMenuArea = this.hideSiteMenuArea.bind(this);
    }

    toggleSiteMenuArea = () => {
        this.setState({
            showSiteMenuArea: !this.state.showSiteMenuArea
        });
    };

    toggleSiteSearchArea = () => {
        this.setState({
            showSiteSearchArea: !this.state.showSiteSearchArea
        });
    };

    changeSiteTitle = (innerSiteTitle: string) => {
        this.setState({
            siteTitle: innerSiteTitle
        });
    };

    hideSiteMenuArea = () => {
        this.setState({
            showSiteMenuArea: false
        });
    };

    getMenuButtonClassList = (menuItem: any) => {
        const addedClass = menuItem.location === SystemTypes.ApplicationLocation.public ? ' menuItemButtonPublic' : ' menuItemButtonLocal';
        return `btn btn-sm ${addedClass}`;
    }

    render() {
        return (
            <BrowserRouter>
                <ScrollToTop />
                <nav className="navbar navbar-light bg-light fixed-top custom-nav topBar">
                    <div className="navbar-text sitePageTitle" dangerouslySetInnerHTML={{ __html: this.state.siteTitle }} />
                    <div className="navbar-text ">
                        <span className="homeIcon">
                            <NavLink to="/" onClick={() => this.changeSiteTitle(siteTitle)}><BsHouse /></NavLink>

                        </span>
                    </div>
                </nav>

                <div className="mainContainer">
                    <div className="pageContent">
                        <Route exact path="/" component={Home} />
                        <Route path="/showcaseGeneral" render={() => <ShowcaseGeneral {...this.state.pageProps} />} />
                        <Route path="/flashcards" component={Flashcards} />
                        <Route path="/howtos" render={() => <Howtos {...this.state.pageProps} />} />
                        <Route path="/quotes" component={Quotes} />
                        <Route path="/manageQuotes" component={ManageQuotes} />
                        {/* DPODMARKER_addPageRoute */}
                        <Route path="/showcaseFlexPictureCards" render={() => <PageShowcaseFlexPictureCards {...this.state.pageProps} />} />
                        <Route path="/intellectualDarkWeb" render={() => <PageIntellectualDarkWeb {...this.state.pageProps} />} />
                        <Route path="/rigorousEthics" render={() => <PageRigorousEthics {...this.state.pageProps} />} />
                        <Route path="/ifc" render={() => <PageIfc {...this.state.pageProps} />} />
                        <Route path="/hbb" render={() => <PageHbb {...this.state.pageProps} />} />
                        <Route path="/techItems" render={() => <PageTechItems {...this.state.pageProps} />} />
                        <Route path="/forays" render={() => <Forays {...this.state.pageProps} />} />
                        <Route path="/fitnessDays" render={() => <FitnessDays {...this.state.pageProps} />} />
                        <Route path="/miniFrameworks" render={() => <MiniFrameworks {...this.state.pageProps} />} />
                        <Route path="/codeExercises" render={() => <CodeExercises {...this.state.pageProps} />} />
                        <Route path="/quizItems" render={() => <QuizItems {...this.state.pageProps} />} />
                        <Route path="/webDevCourse" render={() => <WebDevCourse {...this.state.pageProps} />} />
                        <Route path="/about" render={() => <About {...this.state.pageProps} />} />
                    </div>
                </div>

                {/* SITE MENU */}
                {this.state.showSiteMenuArea &&
                    (
                        <nav className="fixed-bottom siteMenuArea">

                            {this.state.menuItems.filter((menuItem: any) => config.currentApplicationLocation() === SystemTypes.ApplicationLocation.local || menuItem.location === SystemTypes.ApplicationLocation.public).map((menuItem: any) => (
                                <NavLink to={`/${menuItem.idCode}`} key={menuItem.idCode}><button type="button" className={this.getMenuButtonClassList(menuItem)} onClick={() => this.hideSiteMenuArea()}>{menuItem.title}</button></NavLink>
                            ))}

                        </nav>
                    )
                }

                {/* SITE SEARCH */}
                {this.state.showSiteSearchArea &&
                    (
                        <div className="fixed-bottom siteSearchArea">
                            <div className="alert alert-warning" role="alert">
                                <strong>Site Search is under construction.</strong> This will be a site-wide search of all contents.
						</div>
                        </div>
                    )
                }

                {/* FOOTER */}
                <nav className="navbar navbar-light bg-light fixed-bottom custom-nav bottomBar">
                    <div className="navbar-text">
                        <button type="button" className="btn btn-default btn-siteMenu" onClick={() => this.toggleSiteMenuArea()}>Menu</button>
                    </div>
                    <div className="credits">
                        {/* SMARTPHONE VIEW */}
                        <div className="d-inline d-sm-none">
                            <div className="lead">OneSpace by</div>
                            <div className="author name"><a href="about">Edward Tanguay</a></div>
                        </div>

                        {/* COMPUTER/TABLET VIEW */}
                        <div className="d-none d-sm-inline">
                            <div className="leadAndName"><span className="appName">OneSpace</span> created and maintained by <a className="author" href="about">Edward Tanguay</a></div>
                        </div>
                    </div>
                    <div className="navbar-text">
                        <button type="button" className="btn btn-default btn-siteMenu" onClick={() => this.toggleSiteSearchArea()}>Search</button>
                    </div>
                </nav>
            </BrowserRouter>
        );
    }
}

export default Main;