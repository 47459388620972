/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import 'custom/styles/main.scss';
import 'custom/styles/stylesPageAbout.scss';
// import dataPageAbout from 'custom/data/documentType_pageAbout.json';

const pageTitle = 'About this Site';

interface IProps {
    changeSiteTitle: any
}

function PageAbout(props: IProps) {
    useEffect(() => {
        props.changeSiteTitle(pageTitle);
    }, []);
    return (
        <div className="page_about">
            <Helmet>
                <title>Edward Tanguay - OneSpace</title>
            </Helmet>
            <div>
                <div className="panel">
                    <h1>Edward Tanguay - OneSpace</h1>
                    <div className="intro">"A place for everything, and everything in its place."</div>
                    <div className="content">
                        <p><img src="customImages/about/edward_tanguay_profile.jpg" alt="Edward Tanguay" className="author" />Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores nobis tempora eveniet nemo! Animi
                        nostrum sunt magni libero qui vel inventore et saepe voluptas a velit veritatis quibusdam delectus
                        totam, at accusamus corrupti temporibus. Nisi fugit odit, aliquid ipsa itaque pariatur est repellendus
                        distinctio aliquam quis nulla sit reprehenderit consequatur numquam quia error, labore asperiores
                        necessitatibus! Ut ipsum neque, fuga nam, laboriosam ad temporibus repudiandae voluptas quos deleniti
                        dolorum ex reprehenderit aperiam ab, saepe dolorem fugit esse omnis corporis perferendis.
            </p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel doloribus non neque officia architecto
                        assumenda ipsam maxime, quasi, quo saepe sunt vero? Amet culpa aut earum necessitatibus laboriosam
                        praesentium fugit sit ipsum deserunt veniam reprehenderit ex accusantium ipsam repellendus mollitia
                        quasi, sed pariatur cum natus iure officiis. Natus id, placeat consequuntur expedita eveniet provident
                        ab incidunt blanditiis dolorem dolores officia dolorum inventore a necessitatibus temporibus eos maiores
                quas, minus modi!</p>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia dolores, quo facere hic a sapiente
                        minima velit earum, quia, tenetur rem. Magnam cupiditate dolore non id quas? Esse quibusdam quasi
                        consectetur molestiae voluptatibus quo temporibus obcaecati perferendis qui nihil ipsa accusamus, minima
                        aspernatur provident! Fuga deleniti harum tempora illo explicabo blanditiis illum, laudantium incidunt
                        quae quos obcaecati. Expedita, at, natus adipisci inventore similique quas, non saepe et repellendus ex
                        excepturi! Est tempore incidunt quo aliquid, inventore consequuntur nemo provident laboriosam earum,
                        doloremque quaerat impedit cupiditate eum molestias recusandae odio perspiciatis illum quas magnam!
                        Laboriosam, optio minima dolor numquam sit consequatur! Beatae eligendi quia aut dolore architecto
                        soluta. Facilis provident aut officiis cum voluptate animi enim explicabo magnam placeat adipisci.
                Inventore.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id fugit temporibus a vitae? Explicabo incidunt
                        corporis voluptas quis cupiditate aperiam ad ut est suscipit amet, dolore consequuntur porro? Nesciunt
                        minima tenetur laudantium quaerat nam, alias quas esse inventore iure modi amet dolore eligendi natus
                        illo cumque tempore delectus et magni beatae id repellat soluta, earum sapiente. Consequatur voluptatem
                        eos tenetur quod ipsam magni minima placeat quos, maiores reprehenderit voluptatum accusantium a! Quia,
                        aut. Obcaecati reprehenderit nulla id eos aspernatur error tenetur minima nam omnis molestiae aliquam
                        ipsa labore nisi, voluptatem amet ex, sapiente praesentium ullam nobis deserunt eius. Dolores eaque
                        molestias, suscipit harum inventore eius sit tempora nihil voluptate maxime quod. Placeat ut nihil
                        beatae ipsum dolores, saepe iure molestias hic culpa. Consectetur fuga ab repellendus, excepturi, quae
                        optio facere dicta odit ipsam magni atque! Vero doloremque earum suscipit obcaecati consectetur quaerat
                        voluptatibus voluptatum est qui laboriosam, beatae, quasi maiores aperiam excepturi quisquam optio
                perferendis eligendi necessitatibus sed dicta perspiciatis.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageAbout;