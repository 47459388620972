import rawTechItems from 'custom/data/items_techItems.json';
import * as qstr from 'datapod/qtools/qstr';
import comparisons from 'custom/data/items_comparisons.json';
import techTopicGroups from 'custom/data/items_techTopicGroups.json';
import { IComparison } from './model_comparisons';
import { ITechTopicGroup } from './model_techTopicGroups';


const findMatchingTechTopicGroups = (item: ITechItem, techTopicGroupItems: ITechTopicGroup[]): ITechTopicGroup[] => {
    return techTopicGroupItems.filter((techTopicGroup: ITechTopicGroup) => {
        const techItemIdCodes = qstr.breakIntoParts(techTopicGroup.topicList, ',');
        return techItemIdCodes.includes(item.idCode);
    });
}

const findMatchingComparisons = (item: ITechItem, comparisonItems: IComparison[]): IComparison[] => {
    return comparisonItems.filter((comparison: IComparison) => {
        const techItemIdCodes = qstr.breakIntoParts(comparison.techItemList, ',');
        return techItemIdCodes.includes(item.idCode);
    });
}

// {created: 2009}
const parseTechItemBodyForCreated = (techItem: ITechItem) => {
    const regex = /{created:\s*(\d{4})}/;
    techItem.body = techItem.body.replace(regex, (str, p1) => {
        return `created **${p1}**`;
    });
}

const smartDefineIdCode = (item: ITechItem) => {
    item.idCode = qstr.getExtrasValue(item.extras, 'idCode');
    if (qstr.isEmpty(item.idCode)) {
        item.idCode = qstr.forceCamelNotation(item.title);
    }
}

// see also {HTML}.
const parseTechItemBodyForTermLink = (techItem: ITechItem, techItems: ITechItem[]) => {
    const regex = /{(.+?)}/g;
    const mappings = [
        ['Node.js', 'node'],
        ['C++', 'cplusplus'],
        ['C#', 'csharp']
    ];
    techItem.body = techItem.body.replace(regex, (str, p1) => {
        let idCode = qstr.forceCamelNotation(p1);
        for (const mapping of mappings) {
            const from = mapping[0];
            const to = mapping[1];
            idCode = p1 === from ? to : idCode;
        }
        const foundTechItem = techItems.find((m: ITechItem) => m.idCode?.toLowerCase() === idCode.toLowerCase());
        if (foundTechItem?.title !== undefined) {
            return `[${p1}](techItems?id=${foundTechItem.id})`;
        }
        return p1;
    });
}


const fillTechTopicGroupListsWithTechItems = (techItem: ITechItem, techItems: ITechItem[]) => {
    if (techItem.techTopicGroups !== undefined) {
        for (const techTopicGroup of techItem.techTopicGroups) {
            const topicIdCodes = qstr.breakIntoParts(techTopicGroup.topicList, ',');
            techTopicGroup.techItems = techItems.filter((mainTechItem: ITechItem) => {
                const idCode = mainTechItem.idCode === undefined ? '' : mainTechItem.idCode;
                return topicIdCodes.includes(idCode);
            });
        }
    }
}
export interface IRawTechItem {
    id: number;
    genericTermIdCode: string;
    title: string;
    body: string;
    extras: string;
    systemWhenCreated: string;
    systemWhoCreated: string;
}

export interface ITechItem {
    id: number;
    genericTermIdCode: string;
    title: string;
    body: string;
    extras: string;
    systemWhenCreated: string;
    systemWhoCreated: string;
    idCode: string;
    points: string;
    rank: string;
    quick: string;
    webdev: string;
    comparisons: IComparison[];
    techTopicGroups: ITechTopicGroup[];
}

export const buildTechItem = (rawTechItem: IRawTechItem) => {
    const techItem: ITechItem = {
        id: rawTechItem.id,
        genericTermIdCode: rawTechItem.genericTermIdCode,
        title: rawTechItem.title,
        body: rawTechItem.body,
        extras: rawTechItem.extras,
        systemWhenCreated: rawTechItem.systemWhenCreated,
        systemWhoCreated: rawTechItem.systemWhoCreated,
        idCode: qstr.getExtrasValue(rawTechItem.extras, 'idCode'),
        points: qstr.getExtrasValue(rawTechItem.extras, 'points'),
        rank: qstr.getExtrasValue(rawTechItem.extras, 'rank'),
        quick: qstr.getExtrasValue(rawTechItem.extras, 'quick'),
        webdev: qstr.getExtrasValue(rawTechItem.extras, 'webdev'),
        comparisons: [],
        techTopicGroups: []
    }
    smartDefineIdCode(techItem);
    techItem.comparisons = findMatchingComparisons(techItem, comparisons);
    techItem.techTopicGroups = findMatchingTechTopicGroups(techItem, techTopicGroups);
    parseTechItemBodyForCreated(techItem);
    return techItem;
}

export const getTechItems = (): ITechItem[] => {
    const techItems: ITechItem[] = [];
    rawTechItems.forEach((rawTechItem: IRawTechItem) => {
        const item: ITechItem = buildTechItem(rawTechItem);
        techItems.push(item);
    });
    techItems.forEach((techItem: ITechItem) => {
        parseTechItemBodyForTermLink(techItem, techItems);
    });
    techItems.forEach((techItem: ITechItem) => {
        fillTechTopicGroupListsWithTechItems(techItem, techItems);
    });
    return techItems;
}